import styled from 'styled-components'
import { mobile, tablet } from '../../responsive';

const Container = styled.div`
    margin: 7.5rem 5rem 5rem;
    width: 90%;
    min-height: 300px;
    max-height: 600px;
    color: black;
    border-radius: 10px;
    padding: 3rem 0;
    margin-left: 3rem;
    background-image: linear-gradient(to bottom right, #ffffff, #eeffe9);

    ${tablet({
        borderRadius: "10px",
        padding: "2rem 0rem",
        minHeight: "550px"
    })}

    ${mobile({
        margin: "auto",
        marginTop: "7rem",
        minHeight: "1080px",
        width: "95%"
    })}

`;
const Header = styled.div`
    font-size: 2.5rem;
    color: darkblue;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;

    ${mobile({
        fontSize: "1.5rem",
        padding: "0 2rem"
    })}
`;
const Body = styled.div`
    padding: 2rem 5rem;
    font-size: 1.1rem;

    ${mobile({
        padding: "1rem"
    })}
`;
const Content = styled.p`
    line-height: 1.6;
`;
const Footer = styled.div`
    padding: 1rem 7rem;
    height: 350px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 0;

    ${mobile({
        padding: "0",
        marginLeft: "2.5rem"
    })}
`;
const List = styled.ul`
    display: flex;
    flex-direction: column;
`;
const Item = styled.li`
    font-size: 1.1rem;
    margin-bottom: 15px;
    transition: .2s linear;
`;

const Introduction = () => {
  return (
    <Container>
        <Header>
            A TURNKEY SOLUTION PROVIDER
        </Header>
        <Body>
            <Content>
                Prime Tech Enterprise is a turnkey solution provider in BIW Body Shop 
                projects, both Robotic & Manual for Spot Welding as well as MIG 
                Welding Applications. Prime Tech offers advanced technology and engineering 
                capabilities to its clients with a view to ensure quality improvement, 
                reduction of errors and short delivery time.  The team is involved in 
                continuous research of new technology to contribute to the success of 
                the clients. The establishment of a quality assurance system ensures 
                defect-free conversion of design into a weld line. The organization 
                offers enabling technical solutions and a robust turnkey project 
                execution, which includes :
            </Content>
        </Body>
        <Footer>
            <List>
                <Item>Process Planning</Item>
                <Item>3D Design</Item>
                <Item>Robotic simulation with OLP</Item>
                <Item>Control design</Item>
            </List>
            <List>
            <Item>Assembly</Item>
                <Item>CMM (Inspection)</Item>
                <Item>PLC, HMI, Line Integration</Item>
                <Item>Trials at Faith & Buyoff</Item>
            </List>
            <List>
                <Item>Installation and Commissioning on-site</Item>
                <Item>Production Support</Item>
                <Item>Proving & Buyoff</Item>
                <Item>Manufacturing</Item>
            </List>
        </Footer>
    </Container>
  )
}

export default Introduction
