import styled from 'styled-components';
import { mobile } from '../../responsive';

const Container = styled.div`
    background: linear-gradient(
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.5)
    ), 
    url("https://as1.ftcdn.net/v2/jpg/05/70/32/00/1000_F_570320078_Y5JsHEfEstLqETcFFDRmPAVl6imV4qlR.jpg");
    background-repeat: no-repeat;
    height: 300px;
    display: flex;
    align-items: center; 
    justify-content: center;
`;

const Text = styled.div`
    font-size: 3.5rem;
    color: white;
    margin-top: 2.5rem;

    ${mobile({
        paddingLeft: "2rem",
        fontSize: "2.5rem"
    })}
`;

const ServicesHeader = () => {
  return (
    <Container>
        <Text>
            ENGINEERING SERVICES
        </Text>
    </Container>
  )
}

export default ServicesHeader
