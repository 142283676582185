import styled from 'styled-components'
import { mobile, tablet } from '../../responsive';

const Container = styled.div`
    color: black;
    margin: 3rem 5rem;
    padding: 2rem;

    ${mobile({
        margin: "auto",
        padding: "0",
        margin: "1rem"
    })}
`;

const InfoContainer = styled.div`
    display: flex;
    padding: 2rem;
    flex-direction: ${(props) => (props.type) === "reverse" && "row-reverse"};
    margin-bottom: 2rem;
    background: white;
    border-radius: 50px 10px;
    align-items: center;

    ${mobile({
        flexDirection: "column",
        width: "100%",
        borderRadius: "5px"
    })}
`;

const InfoText = styled.div`
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${mobile({
        padding: "0",
    })}
`;

const Title = styled.h1`
`;

const Content = styled.p`
    font-size: 1.1rem;
    line-height: 1.6;
    margin: 1rem 0;
`;

const Image = styled.img`
    border-radius: 10px;

    ${tablet({
        minWidth: "400px",
        minHeight: "400px"
    })}

    ${mobile({
        minWidth: "320px",
        minHeight: "300px",
        maxWidth: "200px",
        maxHeight: "200px",
        borderRadius: "5px"
    })}
`;

const ServicesContent = () => {
  return (
    <Container>
        <InfoContainer>
            <InfoText>
                <Title>
                    Process Planning
                </Title>
                <Content>
                    Involves complete body shop planning with the layout, 
                    weld spot selection / distribution, clamping plan / location plan, cycle time study & operator 
                    ergonomics study.
                </Content>
            </InfoText>
            <Image src="https://images.pexels.com/photos/834892/pexels-photo-834892.jpeg?auto=compress&cs=tinysrgb&w=600" />
        </InfoContainer>
        <InfoContainer type="reverse">
            <InfoText>
                <Title>
                    3D Design
                </Title>
                <Content>
                    Involves designing jigs/fixtures, robot grippers and other equipments as per the plan, 
                    required in the BIW welding line. It also includes conducting internal design reviews to 
                    optimize the design in terms of its weld accessibility, operator ergonomics and quality. 
                    It involves interacting with the Robot Simulation Engineers for Robot reachability with 
                    minimum repositions. Finally, its role is in conducting DAP with customer for getting 
                    approvals to go ahead for manufacturing.
                </Content>
            </InfoText>
            <Image src="https://images.pexels.com/photos/1476321/pexels-photo-1476321.jpeg?auto=compress&cs=tinysrgb&w=600" />
        </InfoContainer>
        <InfoContainer>
            <InfoText>
                <Title>
                    Robot Simulation with OLP Offline Program
                </Title>
                <Content>
                The team creates optimized robot cell in terms of the layout space, payload and reachability. 
                This step supports customers in the selection of robots based on the payload and reachability. 
                It creates a robot path, interacts with the design team for any modifications and after 
                finalization, creates OLP (Offline Robot Program) for quick installation of line.
                </Content>
            </InfoText>
            <Image src="https://t4.ftcdn.net/jpg/03/07/42/83/240_F_307428398_AHtNBIf4phX0YLDWqXps7hgnFXN0RbES.jpg" style={{height: "400px", width: "600px"}}/>
        </InfoContainer>
        <InfoContainer type="reverse">
            <InfoText>
                <Title>
                    2D Detailing
                    (Manufacturing Drawing with BOM)
                </Title>
                <Content>
                    After getting customer approvals on the 3D Designs, we create manufacturing drawings 
                    with the BOM (Bill of Material). Also, we support customers with NC Patch Data / 
                    IGES models of NC Blocks preparation for manufacturing, inspection data & inspection 
                    sheet preparation and preparation of cylinder and sensor sheet (Pneumatic Circuit 
                    diagram & Sensor Logic Matrix).
                </Content>
            </InfoText>
            <Image src="https://as2.ftcdn.net/v2/jpg/04/84/99/77/1000_F_484997767_ee6UH5iDaCu8Vn5r7O5uWMN4CxKGARsV.jpg" style={{height: "400px", width: "600px"}}/>
        </InfoContainer>
    </Container>
  )
}

export default ServicesContent
