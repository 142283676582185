import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import PrototypeHeader from '../components/prototype support/PrototypeHeader'
import PrototypeContent from '../components/prototype support/PrototypeContent'
import GoHome from '../components/GoHome'

const PrototypeSupport = () => {
  return (
    <div>
      <Header />
      <PrototypeHeader />
      <PrototypeContent />
      <GoHome />
      <Footer />
    </div>
  )
}

export default PrototypeSupport
