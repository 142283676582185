import styled from 'styled-components';
import { mobile } from '../../responsive';

const Container = styled.div`
    background: linear-gradient(
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.5)
    ), 
    url("https://t4.ftcdn.net/jpg/03/27/51/23/240_F_327512324_3l3yvRGrUeR1OlwRSIR6KYvNjb9sYurR.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Text = styled.div`
    font-size: 3.5rem;
    color: white;
    margin-top: 2.5rem;

    ${mobile({
        paddingLeft: "2rem",
        fontSize: "2.5rem"
    })}
`;

const PrototypeHeader = () => {
  return (
    <Container>
        <Text>
            PROTOTYPE SUPPORT
        </Text>
    </Container>
  )
}

export default PrototypeHeader
