import styled from "styled-components"
import { mobile, tablet } from "../../responsive";

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 10rem;
    justify-content: center;
    padding: 3rem;
    color: #ffffff;
    margin: 5rem 0;
    ${mobile({
        display: "flex",
        flexDirection: "column",
        gridGap: "4rem",
        margin: "0"
    })}
`;

const Box = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Icon = styled.i`
    font-size: 4.5rem;

    ${tablet({
        fontSize: "4rem"
    })}
`;

const Count = styled.h2`
    margin: 1rem 0;
    font-size: 2.5rem;

    ${tablet({
        fontSize: "2rem"
    })}
`;
    
const Title = styled.h5`
    font-size: 1.5rem;
    ${tablet({
        fontSize: "1.2rem"
    })}
`;

const Status = () => {
  return (
    <Container>
        <Box>
            <Icon className="fa-solid fa-user" ></Icon>
            <Count>30+</Count>
            <Title>Employees</Title>
        </Box>
        <Box>
            <Icon className="fa-solid fa-check-to-slot" ></Icon>
            <Count>70+</Count>
            <Title>Projects Completed</Title>
        </Box>
        <Box>
            <Icon className="fa-solid fa-globe" ></Icon>
            <Count>17+</Count>
            <Title>Customers</Title>
        </Box>
    </Container>
  )
}

export default Status
