import styled from "styled-components";
import { mobile, tablet } from "../../responsive";

const Container = styled.div`
    display: flex;
    margin: 2rem 4rem;

    ${mobile({
        margin: "0",
        display: "flex",
        flexDirection: "column"
    })}
`;
const InfoContainer = styled.div`
    flex: 1;
    background-color: white;
    color: white;
    height: 250px;
    margin-right: 1.5rem;

    
    ${tablet({
        borderRadius: "10px"
    })}
    ${mobile({
        margin: ".5rem .7rem",
        borderRadius: "5px"
    })}
    `;
const Heading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #CE2C3C;
    height: 3.5rem;
    font-size: 1.2rem;
    i{
        margin-right: .5rem;
    }

    ${tablet({
        borderRadius: "10px 10px 0 0"
    })}

    ${mobile({
        borderRadius: "5px 5px 0 0"
    })}
`;
const Content = styled.div`
    color: black;
    height: 80%;
    font-size: .9rem;
    line-height: 1.6;
    padding: 1rem 1.3rem;

    ${tablet({
        fontSize: ".9rem"
    })}
`;
const Span = styled.span`
    margin-top: 1rem;
    display: flex;
    font-size: 17px;
`;
const Contact = () => {
  return (
    <Container>
        <InfoContainer>
            <Heading>
                <i className="fa-solid fa-location-dot" />
                ADDRESS
            </Heading>
            <Content>
                PRIME TECH Enterprise <br></br>
                An ISO 9001: 2015 CERTIFIED COMPANY
                N0-36/19
                Pillayar Koil 4th Street <br></br>
                Ekkattuthangal-600032.
                <Span>
                    <a href="https://maps.app.goo.gl/5rErUjV7oXsJjgQr6" target="_blank">Click here to get the location</a>
                </Span>
            </Content>
        </InfoContainer>
        <InfoContainer>
            <Heading>
                <i className="fa-solid fa-envelope" />
                EMAIL US
            </Heading>
            <Content>
                In case of any questions related to our offerings, you could mail us or reach us by filling the form below.
                <br></br>
                <br></br>
                <Span>
                    <a href="mailto: primetech41@gmail.com">primetech41@gmail.com</a>
                </Span>
            </Content>
        </InfoContainer>
        <InfoContainer>
            <Heading>
                <i className="fa-solid fa-phone" />
                CALL US
            </Heading>
            <Content>
                For further queries, you could connect with us by calling on : +91 86050 04 354 (IST). 
                We’re available from Mon-Sat (8:30am – 5:45pm).
                <Span>
                    <a href="tel: 9884538205" >Call now: 9884538205</a>
                </Span>
            </Content>
        </InfoContainer>
    </Container>
  )
}

export default Contact
