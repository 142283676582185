import React, { useState } from "react"
import { Link } from "react-router-dom"
import { path } from "../data"
import styled from "styled-components"
import { mobile } from "../responsive";

const Search = styled.div`
  position: relative;
  background-color: white;
  border-radius: 50px;

  ${mobile({
    marginLeft: "1.5rem"
  })}
`;

const SearchInput = styled.div`
  border: 2px solid grey;
  display: flex;
  align-items: center;
  border-radius: 50px;
  height: 50px;
  width: 280px;

  ${mobile({
    height: "45px",
    width: "250px",
  })}
`;

const Input = styled.input`
  padding: .3rem;
  font-size: 1.1rem;
  padding-left: 20px;
  border: none;  
  border-radius: 20px;

  &:focus{
    outline: none;
  }

  ${mobile({
    width: "90%"
  })}
`;

const SearchIcon = styled.i`
  font-size: 1.3rem;

  ${mobile({
    marginLeft: "-.5rem"
  })}
`;
const CloseIcon = styled.i`
  font-size: 1.3rem;
  cursor: pointer;

  ${mobile({
    marginLeft: "-.5rem"
  })}
`;

const DataResult = styled.div`
  position: absolute;
  margin-top: 5px;
  width: 290px;
  max-height: 200px;
  background-color: #eee;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-image: linear-gradient( 174.2deg,  rgba(255,244,228,1) 7.1%, rgba(240,246,238,1) 67.4% );


  ::-webkit-scrollbar{
    display: none;
  }
`;

const Item = styled(Link)`
  text-decoration: none;
  font-size: 1.1rem;
  padding: .4rem;
  color: black;
  height: 50px;
  display: flex;
  align-items: center;
  transition: all 0s linear;

  p{
    margin-left: 10px;
  }
  &:hover{
    background: #153391;
    color: white;
  }
`;

function SearchBar({ placeholder}) {
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  const handleFilter = (event) => {
    const searchWord = event.target.value;

    setWordEntered(searchWord);

    const newFilter = path.filter((value) => {
      return value.title.toLowerCase().includes(searchWord.toLowerCase());
    });

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };

  return (
    <Search>
      <SearchInput>
        <Input
          type="text"
          placeholder="Product/Service"
          value={wordEntered}
          onChange={handleFilter}
        />
        {filteredData.length === 0 && wordEntered.length === 0 ? <SearchIcon className="fa-solid fa-search" /> : <CloseIcon className="fa-solid fa-close" onClick={clearInput}/>}
      </SearchInput>
      
      {filteredData.length !== 0 &&  (
        <DataResult>
          {filteredData.map((value, key) => {
            return (
              <Item to={value.link} onClick={clearInput}>
                <p>{value.title}</p>
              </Item>
            );
          })}
        </DataResult>
      )}
    </Search>
  );
}

export default SearchBar;