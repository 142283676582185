import styled from 'styled-components'
import { mobile,tablet } from '../../responsive';

const Container = styled.div`
    color: black;
    margin: 3rem 5rem;
    padding: 2rem;

    ${mobile({
        margin: "auto",
        padding: "0",
        margin: "1rem"
    })}
`;

const InfoContainer = styled.div`
    display: flex;
    padding: 2rem;
    flex-direction: ${(props) => (props.type) === "reverse" && "row-reverse"};
    margin-bottom: 2rem;
    background-color: white;
    border-radius: 40px 10px;
    padding-bottom: 2rem;
    justify-content: center;
    align-items: center;

    ${mobile({
        flexDirection: "column",
        width: "100%",
        borderRadius: "5px",
        padding: "1rem"
    })}
`;

const InfoText = styled.div`
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${mobile({
        padding: "0",
    })}
`;

const Title = styled.h1`
`;

const Content = styled.p`
    font-size: 1.1rem;
    line-height: 1.6;
    margin: 1rem 0;
`;

const Image = styled.img`
    border-radius: 10px;

    ${tablet({
        width: "400px",
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    })}

    ${mobile({
        maxWidth: "320px",
        maxHeight: "300px",
        borderRadius: "5px"
    })}
`;

const BodyWeldingContent = () => {
  return (
    <Container>
        <InfoContainer>
            <InfoText>
                <Title>
                    Manual Spot Welding Lines – BIW
                </Title>
                <Content>
                For low volume/low JPH (jobs-per-hour-rate) lines, Prime Tech offers Manual Spot Welding Lines/Fixtures/Jigs. 
                The design team takes utmost care of operator ergonomics (like loading height, reach, gun orientation and 
                handling) as well as fatigue, while designing these manual welding fixtures/jigs. Manual Spot Welding 
                Lines is for low volumes and the complete Bodyshop Solutions is provided.
                </Content>
            </InfoText>
            <Image src="https://images.pexels.com/photos/2381463/pexels-photo-2381463.jpeg?auto=compress&cs=tinysrgb&w=600" />
        </InfoContainer>
        <InfoContainer type="reverse">
            <InfoText>
                <Title>
                    Robotic Spot Welding Lines/Cells-BIW
                </Title>
                <Content>
                Prime Tech has supplied multiple turnkey Spot Welding lines Jigs and Fixtures for Rear Underbody (Rear Floor Panel Asm, Rear 
                Member / Ladder Asm & marriage) to its customers. Both Robotic and Manual Lines for high volumes with 46 seconds 
                Cycle time & low volumes with 120 seconds Cycle Time respectively. Some of these projects were Green Field, which 
                were completely new with multi-model flexible production lines. Whereas, some of them were brown field, where new 
                models were to be introduced in the existing line.
                </Content>
            </InfoText>
            <Image src="https://media.istockphoto.com/id/482854273/photo/high-tech-robotic-welder-in-modern-metal-factory.jpg?s=612x612&w=0&k=20&c=9AsstBpncW4Z6h4REm4DX3mBmHnYZZbC_M-thmOMDCE=" style={{height: "400px", width: "400px"}} />
        </InfoContainer>
        <InfoContainer>
            <InfoText>
                <Title>
                    Robotic MIG Welding Lines-Chassis
                </Title>
                <Content>
                MIG welding is one of Prime Tech's strong competencies, where robotic MIG welding solutions are offered, 
                mainly for Chassis & Underbody safety assemblies like Chassis, Rear Axle – RTB, Sub Frames – FCM and 
                Lower Cross Arm – LCA. The team of experts at Prime Tech possess advanced skills in the robotic MIG welding 
                lines. The team has many years of experience in executing MIG Welding Projects. As a result of this, 
                the team can achieve best results with good penetration & minimum distortion.
                </Content>
            </InfoText>
            <Image src="https://images.pexels.com/photos/5845969/pexels-photo-5845969.jpeg?auto=compress&cs=tinysrgb&w=600" />
        </InfoContainer>
        <InfoContainer type="reverse">
            <InfoText>
                <Title>
                    Roller Hemming – Closures
                </Title>
                <Content>
                Prime Tech has designed and installed lines/cells for Closures (Front Door LH/RH, Rear Door LH/RH, Hood, Tail 
                Gate/Trunk Lid), starting with Inner Welding Cells, Outer Sealing Fixtures/Jigs, Pre-Hem/Pre-Marriage 
                Fixtures/Jigs, Hemming (Roller Hemming and Press Hemming), after Hem Welding and Hinge Fitment. A 
                highly experienced team at Prime Tech supports customers by providing input on Hem Flange length and 
                opening angle to get best hemming quality.
                </Content>
            </InfoText>
            <Image src="https://images.pexels.com/photos/259984/pexels-photo-259984.jpeg?auto=compress&cs=tinysrgb&w=600" />
        </InfoContainer>
    </Container>
  )
}

export default BodyWeldingContent
