import styled from "styled-components";
import { mobile } from "../../responsive";

const Container = styled.div`
    margin: 10px 5px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .5rem;

    ${mobile({
        margin: "auto",
        width: "90vw"
    })}
`;

const Image = styled.img`
    height: 300px;
    width: 400px;

    ${mobile({
        width: "320px"
    })}
`;

const Text = styled.p`
    padding-top: .5rem;
    color: darkblue;
    width: 300px;
    display: flex;
    justify-content: center;
    font-size: 1.3rem;
`;

const Riveting = ({item}) => {
  return (
    <Container>
        <Image src={item.img} />
        <Text>
            {item.text}
        </Text>
    </Container>
  )
}

export default Riveting
