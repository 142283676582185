import styled from 'styled-components';
import { mobile } from '../../responsive';

const Container = styled.div`
    color: black;
    margin: 3rem 5rem;
    padding: 2rem;

    ${mobile({
        margin: "auto",
        padding: "0",
        margin: "1rem"
    })}
`;

const InfoContainer = styled.div`
    display: flex;
    padding: 2rem;
    flex-direction: ${(props) => (props.type) === "reverse" && "row-reverse"};
    margin-bottom: 2rem;
    background: white;
    border-radius: 50px 10px;
    align-items: center;

    ${mobile({
        flexDirection: "column",
        width: "100%",
        borderRadius: "5px",
        padding: "1rem"
    })}
`;

const InfoText = styled.div`
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${mobile({
        padding: "0",
    })}
`;

const Title = styled.h1`
`;

const Content = styled.p`
    font-size: 1.1rem;
    line-height: 1.6;
    margin: 1rem 0;
`;

const Image = styled.img`
    border-radius: 10px;
    min-width: 400px;
    min-height: 400px;

    ${mobile({
        minWidth: "320px",
        minHeight: "300px",
        maxWidth: "200px",
        maxHeight: "200px",
        borderRadius: "5px"
    })}
`;

const InnovationContent = () => {
  return (
    <Container>
        <InfoContainer>
            <InfoText>
                <Title>
                    3-Axis Positioners
                </Title>
                <Content>
                    3-Axis positioners are required specially in case of the MIG welding process for carrying out synchronized welding, for eg. in the Rear 
                    Axle-RTB welding between U-Beam & Trailing Arm. When Robots are welding on 1-Trunnion Axis, the operator will be loading on the 2-Trunnion 
                    Axis. After both finish their work, then it rotates on the 3-Turntable Axis. These positioners are widely used by Tier-1’s to manufacture the 
                    sub-assembly. As it is driven by servo motor, it has got a high precision stopping and speed. Prime Tech develops these positioners based on the 
                    requirements and specifications of the customers.
                </Content>
            </InfoText>
            <Image src="https://faithautomation.com/wp-content/uploads/2019/07/01_3-Axis.png" />
        </InfoContainer>
        <InfoContainer type="reverse">
            <InfoText>
                <Title>
                    Single Axis Positioners
                </Title>
                <Content>
                    For making spare doors, where there is more cycle time available as it is low volume. Prime Tech has developed Robotic Solution for a Japanese 
                    customer to feed-in and take-out Multimodel Closures(front door LH/RH, Rear door LH/RH, Hood and tail gate) from the press for the hemming 
                    operation. As it is a Robotic Solution, any number of different types of Closures can be planned for making the spares.
                </Content>
            </InfoText>
            <Image src="https://faithautomation.com/wp-content/uploads/2019/06/01_3-Axis-Single-Positioners.png" />
        </InfoContainer>
        <InfoContainer>
            <InfoText>
                <Title>
                Panel Piercing Solutions BIW Body Shop
                </Title>
                <Content>
                    Prime Tech has developed many piercing solutions for customers based on their requirements. Manual piercing fixture/jig is planned in case of low 
                    volume and small holes to be pierced. For larger diameter holes, where high force is required, the organization developed hydraulic piercing 
                    fixtue/jig. Prime Tech has also delivered pneumatic piercing fixture/jig for customers, where hydraulic power packs are not required. It is also 
                    better in terms of maintenance of the equipment.
                </Content>
            </InfoText>
            <Image src="https://t3.ftcdn.net/jpg/01/10/51/74/240_F_110517429_xCKRhGsBHL97SdLTuFeYYp0qyOVEQ27E.jpg" />
        </InfoContainer>
        <InfoContainer type="reverse">
            <InfoText>
                <Title>
                    Special Clamps
                </Title>
                <Content>
                    In the Jigs/Fixtures, Prime Tech sometimes need special clamps due to Hole Size or area of clamping, where there is no accessibility or also due to 
                    urgent requirement from the customer, where regular clamps cannot be bought. For such situations, the Innovation team has designed & developed 
                    special Clamps fulfilling the needs of the customer and the project.
                </Content>
            </InfoText>
            <Image src="https://faithautomation.com/wp-content/uploads/2019/06/04_Special-Clamps.png" />
        </InfoContainer>
    </Container>
  )
}

export default InnovationContent
