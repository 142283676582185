import styled from "styled-components";
import { mobile } from "../responsive";

const Container = styled.div`
    display: flex;
    flex-direction: ${(props) => 
        ((props.direction) === "reverse" && "row-reverse")
    };
    margin-bottom: 3rem;

    ${mobile({
        display: "flex",
        flexDirection: "column-reverse",
        borderRadius: "5px",
        padding: "1rem"
    })}
`;

const ImageContent = styled.div`
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
`;
    
const Image = styled.img`
    height: 100%;
    object-fit: cover;

    ${mobile({
        width: "320px",
        borderRadius: "5px"
    })}
`;
    
const Content = styled.div`
    flex: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.6;
`;

const Heading = styled.h4`
    font-size: 1.9rem;
    margin-bottom: .5rem;
`;

const Text = styled.p`
    font-size: 16px;
`;

const ProjectData = ({item}) => {
  return (
    <Container direction={item.direction}>
        <ImageContent direction={item.direction}>
            <Image src={item.img} />
        </ImageContent>
        <Content>
            <Heading>{item.heading}</Heading>
            <Text>{item.text}</Text>
        </Content>
    </Container>
  )
}

export default ProjectData
