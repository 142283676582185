import styled from 'styled-components';
import { mobile, tablet } from '../../responsive';

const Container = styled.div`
    margin: 3rem;
    padding: 4rem 4rem;

    ${mobile({
        padding: "0rem",
        margin: "0rem .5rem"
    })}
`;
const Info = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    flex-direction: ${(props) => (props.type) === "reverse" && "row-reverse"};
    border-radius: 20% 10px;
    padding: 3rem; 
    background: rgba(255, 255, 255, 0.96);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(6.3px);
    border: 1px solid rgba(255, 255, 255, 0.31);

    ${mobile({
        padding: "1rem",
    })}

    ${tablet({
        display: "flex",
        flexDirection: "column-reverse",
        borderRadius: "5px"
        
    })}

`;
const InfoContainer = styled.div`
    flex: 4;
    margin-right: 4rem;
    font-size: 1rem;
    line-height: 1.7;
    margin-left: ${(props) => (props.type) === "reverse" && "4rem"};

    ${tablet({
        marginRight: "0rem",
        fontSize: "1rem"
    })}
`;
const Title = styled.h2`
    font-size: 28px;
    margin-bottom: 1rem;

    ${mobile({
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
    })}

    ${tablet({
        display: "flex",
        justifyContent: "center"
    })}
`;
const List = styled.ul`
    margin-left: 1rem;

    ${mobile({
        width: "90vw",
        paddingRight: "2rem"
    })}

    ${tablet({
        marginLeft: "0rem"
    })}
`;
const Item = styled.li`
    ${mobile({
        marginLeft: "1rem"
    })}
`;

const Span = styled.span`
    font-weight: bold;
`;

const ImgContainer = styled.div`
    flex: 3;
    height: 400px;
    position: relative;
    
    img{
        height: 100%;
        width: 100%;
        padding: 1rem;
        border-radius: 10px;

        ${mobile({
            padding: "0rem",
            margin: ".7rem 0",
            borderRadius: "5px"
        })}
    }

    ${tablet({
        width: "100%"
    })}
`;
const AboutUsContent = () => {
  return (
    <Container>
        <Info>
            <InfoContainer>
                Prime Tech is a turnkey solution provider specializing in BIW Body Shop Welding Line Projects. Prime Tech leverages 
                field-proven accumulated working experience of 100 years with global giants, to design and deliver superior automotive 
                welding line solutions. Prime Tech partners with its customers to Process Plan, Design & Integrate technology to reliably meet 
                the production needs and always delivering high-quality execution to meet customer launch goals, each and every time.The 
                organization optimizes and simplifies automation design and delivery, preventing time and cost overruns. There is a 
                dedicated team working with the best-in-class hardwares to ensure timely and high-quality delivery to the customers. 
                The delivery is handed over to the customer post multiple verification processes for quality, safety and yield. The internal 
                design review as well as regular project review and tracking processes helps deliver top-quality products and services to 
                the customers.
            </InfoContainer>
            <ImgContainer>
                <img alt="prime tech" src="https://as1.ftcdn.net/v2/jpg/03/08/60/20/1000_F_308602055_R8IQFDA1NdJJbeTFLEf6XhtZn7wsDrtV.jpg" /> 
            </ImgContainer>
        </Info>
        <Info type="reverse">
            <InfoContainer>
                <Title>Our Core Values</Title>
                <List>
                    <Item>
                        <Span>Accountability: </Span> 
                        Each one of us at Prime Tech is extremely responsive to the needs of our customer’s. We take turnkey ownership not 
                        just for our tasks but for our projects too.
                    </Item>
                    <Item>
                        <Span>Integrity: </Span>
                        We put immense thought, focus and effort into our commitments, ensuring we honour our word given to our customers 
                        and to each other at all times.
                    </Item>
                    <Item>
                        <Span>Teamwork: </Span>
                        We believe in always working together as a team, taking collective responsibility and individual accountability.
                    </Item>
                    <Item>
                        <Span>Leadership: </Span>
                        We set high standards in Tooling and Automation Design, Process Planning, Efficiency and Execution – on time, each time.
                    </Item>
                    <Item>
                        <Span>Respect: </Span>
                        We respect and honour ourselves and our customers and suppliers alike, always behaving with utmost courtesy and professionalism.
                    </Item>
                </List>
            </InfoContainer>
            <ImgContainer>
                <img alt="company" src="https://images.unsplash.com/photo-1601119462748-2d612d1c9d86?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzR8fHdvcmtlcnN8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60" />
            </ImgContainer>
        </Info>
        <Info>
            <InfoContainer>
                <Title>Agile • Passionate • Committed</Title>
                <List>
                    <Item>
                        <Span>Agile: </Span> 
                        Prime Tech houses the right blend of skills and attitude to develop world-class automotive body welding design and 
                        engineering capabilities for the clients. Prime Tech has an in-depth knowledge and the capability of adapting itself 
                        to the specific needs of the business and technical requirements of the solution. Greater business agility has 
                        led to the execution of more effective solutions in a time bound manner.
                    </Item>
                    <Item>
                        <Span>Passionate: </Span>
                        As a turnkey solution provider, Prime Tech has a team of passionate engineers working closely to achieve the business 
                        goals of the clients. The team is passionate about delivering solutions as per the commitments made to the 
                        clients. Prime Tech Automation combines automation technology with engineering design capabilities, and proven hands-on 
                        execution skills resulting in optimized processes, flawless execution and faster time-to-launch.
                    </Item>
                    <Item>
                        <Span>Committed: </Span>
                        Prime Tech is committed to meet the business goals of the clients by achieving superior quality automation solutions with 
                        a predictable time to market. With many valuable years of experience, Prime Tech possesses the skills and knowledge to deliver 
                        fail-proof turnkey solutions as well as ensuring precise execution.                    
                    </Item>
                </List>
            </InfoContainer>
            <ImgContainer>
                <img alt="company" src="https://images.pexels.com/photos/1108101/pexels-photo-1108101.jpeg?auto=compress&cs=tinysrgb&w=600" />
            </ImgContainer>
        </Info>
    </Container>
  )
}

export default AboutUsContent
