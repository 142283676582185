import styled from "styled-components";
import { Link } from "react-router-dom";
import { mobile } from "../responsive";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -2rem 0 4rem;

    ${mobile({
        marginTop: "2rem"
    })}
`;    
const Button = styled(Link)`
    background: white;
    padding: 1rem 2rem;
    font-size: 1.3rem;
    text-decoration: none;
    color: black;
    border-radius: 50px;

    i{
        padding-right: 1rem;
    }

    &:hover{
        background: #153391;
        color: white;
    }
`;

const PreviousWorkButton = () => {
  return (
    <Container>
        <Button to="/">
            <i className="fa-solid fa-arrow-left" />
            Previous Page
        </Button>
    </Container>
  )
}

export default PreviousWorkButton
