import styled from 'styled-components';
import { mobile } from '../responsive';

const Container = styled.div`
    background: linear-gradient(
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.1)
    ), 
    url("https://t4.ftcdn.net/jpg/04/14/70/95/240_F_414709559_IBkTgeZjxkepQR5x8vgsP5ie93EApqFx.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${mobile({
        height: "200px"
    })}
`;
const Text = styled.div`
    font-size: 3rem;
    color: white;
    margin-top: 2.5rem;
`;


const WorksHeader = () => {
  return (
    <Container>
        <Text>
            OUR WORKS
        </Text>
    </Container>
  )
}

export default WorksHeader
