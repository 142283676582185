import styled from 'styled-components';
import { mobile } from '../../responsive';

const Container = styled.div`
    color: black;
    margin: 3rem 5rem;
    padding: 2rem;

    ${mobile({
        margin: "auto",
        padding: "0",
        margin: "1rem"
    })}
`;

const InfoContainer = styled.div`
    display: flex;
    padding: 2rem;
    flex-direction: ${(props) => (props.type) === "reverse" && "row-reverse"};
    margin-bottom: 2rem;
    background: white;
    border-radius: 50px 10px;
    align-items: center;

    ${mobile({
        flexDirection: "column",
        width: "100%",
        borderRadius: "5px",
        padding: "1rem"
    })}
`;

const InfoText = styled.div`
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${mobile({
        padding: "0",
    })}
`;

const Title = styled.h1`
`;

const Content = styled.p`
    font-size: 1.1rem;
    line-height: 1.6;
    margin: 1rem 0;
`;

const Image = styled.img`
    min-height: 400px;
    min-width: 400px;
    border-radius: 10px;

    ${mobile({
        minWidth: "320px",
        minHeight: "300px",
        maxWidth: "200px",
        maxHeight: "200px",
        borderRadius: "5px"
    })}
`;

const PrototypeContent = () => {
  return (
    <Container>
        <InfoContainer>
            <InfoText>
                <Title>
                Simple Fixtures for Proto Body Making
                </Title>
                <Content>
                The design team at Prime Tech has an experience of designing minimum welding fixtures. These welding fixtures are developed 
                through multiple stages in the same fixture in order to build the proto body/assembly. The multiple stages are designed 
                by using simple changeover or drop-out units, which makes it highly cost-effective.
                </Content>
            </InfoText>
            <Image src="https://as1.ftcdn.net/v2/jpg/01/10/51/74/1000_F_110517429_xCKRhGsBHL97SdLTuFeYYp0qyOVEQ27E.jpg" style={{ width: "800px"}}/>
        </InfoContainer>
    </Container>
  )
}

export default PrototypeContent
