import styled from 'styled-components'
import { mobile, tablet } from '../../responsive';

const Container = styled.div`
    color: black;
    margin: 3rem 5rem;
    padding: 2rem;

    ${mobile({
        margin: "auto",
        padding: "0",
        margin: "1rem"
    })}
`;

const InfoContainer = styled.div`
    display: flex;
    padding: 2rem;
    flex-direction: ${(props) => (props.type) === "reverse" && "row-reverse"};
    margin-bottom: 2rem;
    background: white;
    border-radius: 50px 10px;
    align-items: center;

    ${mobile({
        flexDirection: "column",
        width: "100%",
        borderRadius: "5px",
        padding: "1rem"
    })}
`;

const InfoText = styled.div`
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${mobile({
        padding: "0",
    })}
`;

const Title = styled.h1`
`;

const Content = styled.p`
    font-size: 1.1rem;
    line-height: 1.6;
    margin: 1rem 0;
`;

const Image = styled.img`
    border-radius: 10px;
    min-width: 400px;
    height: 400px;

    ${mobile({
        minWidth: "320px",
        minHeight: "300px",
        maxWidth: "200px",
        maxHeight: "200px",
        borderRadius: "5px"
    })}
`;

const MaterialHandlingContent = () => {
  return (
    <Container>
        <InfoContainer>
            <InfoText>
                <Title>
                Skid/Pallet Transfer System with Roller Bed
                </Title>
                <Content>
                    Skid/pallet through roller beds are widely used transfer systems in body shop. In this kind of material handling, the skid/pallet is 
                    first loaded onto the first station, post which the body is loaded onto it. The skid/pallet would ideally carry the body from the first 
                    station up until the last station, where the body would be unloaded and skid/ pallet would return to its start position.This is a flexible 
                    system where the in between station transfers can be managed independently.
                </Content>
            </InfoText>
            <Image src="https://faithautomation.com/wp-content/uploads/2019/07/02_Skid-pallet-transfer-system_2.png" />
        </InfoContainer>
        <InfoContainer type="reverse">
            <InfoText>
                <Title>
                    Scissor Lift
                </Title>
                <Content>
                    Scissor lift operators and site managers at corporations large and small take on jobs that can be handled more efficiently and safely with 
                    a mechanical lift that is properly matched to the task at hand. Operators and site managers use them to increase productivity, improve 
                    safety and save money, but they will only succeed when they have the right equipment for the right project.
                </Content>
            </InfoText>
            <Image src="https://faithautomation.com/wp-content/uploads/2019/07/07_Scissor-Lifter.png" />
        </InfoContainer>
    </Container>
  )
}

export default MaterialHandlingContent
