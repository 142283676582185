import styled from "styled-components";
import React from 'react';

const Container = styled.div`
    color: white;
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    margin: 3rem 0rem;
    background: rgba(255, 255, 255, 0.2);
`;

const Arrow = styled.div`
    width: 50px;
    height: 50px;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${(props) =>
        props.direction === "left" && "200px"};
    right: ${(props) =>
        props.direction === "right" && "200px"};
    margin: auto;
    cursor: pointer;
    opacity: 1;
    z-index: 2;
    font-size: 2.8rem;
`;

const Wrapper = styled.div`
    height: 350px;
    display: flex;
    transform: translateX(${(props) => 
        (props.slideIndex * -100) }vw);
    transition: all 1.5s ease;
`;

const InfoContainer = styled.div``;

const Image = styled.div``;

const AboutUsCopy = () => {
  return (
    <Container>
        <Arrow direction="left">
            <i className="fa-solid fa-angle-left"></i>
        </Arrow>
        <Wrapper>
            <InfoContainer>
                <Image>
                    <img src="https://images.unsplash.com/photo-1521791136064-7986c2920216?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fGNvbXBhbnl8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60" alt="image1"/>
                </Image>
            </InfoContainer>
            <InfoContainer>
                <Image>
                    <img src="https://images.unsplash.com/photo-1596008194705-2091cd6764d4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8dGFyZ2V0fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60" alt="image2"/>
                </Image>
            </InfoContainer>
            <InfoContainer>Hello People!</InfoContainer>
        </Wrapper>
        <Arrow direction="right">
            <i className="fa-solid fa-angle-right"></i>
        </Arrow>
    </Container>
  )
}

export default AboutUsCopy
