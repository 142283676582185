import Competencies from '../components/home/Competencies';
import Facilities from '../components/home/Facilities';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Introduction from '../components/home/Introduction';
import Statement from '../components/home/Statement';
import Status from '../components/home/Status';
import Vendor from '../components/home/Vendor';
import WhyPrimeTech from '../components/home/WhyPrimeTech';

const Home = () => {
  return (
    <div>
      <Header />
      <Introduction />
      <Statement />
      <Competencies />
      <Facilities />
      <WhyPrimeTech />
      <Status />
      <Vendor />
      <Footer />
    </div>
  )
}

export default Home
