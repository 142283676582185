import styled from "styled-components";
import ProjectData from './ProjectData';
import { projectItem } from "../data";
import Header from "./Header";
import Footer from "./Footer";
import PreviousWorkButton from "./PreviousWorkButton";
import { mobile } from "../responsive";

const Container = styled.div`
  margin: 4rem;
  margin-top: 6rem;
  background-color: white;
  padding: 2rem 4rem;

  ${mobile({
    display: "flex",
    flexDirection: "column",
    width: "90vw",
    margin: "auto",
    padding: "0",
    borderRadius: "5px"
  })}
`;

const Content = styled.div`
  ${mobile({
    width: "100%",
    margin: "auto",
  })}
`;
const CarSeatFrame = () => {
  return (
    <>
      <Header />
      <Container>
        <Content>
        {projectItem.map( (item) =>{
          return (
            <ProjectData item={item} key={item.id} />
          );
        })}
        </Content>
      </Container>
      <PreviousWorkButton />
      <Footer />
    </>
  )
}

export default CarSeatFrame
