import styled from "styled-components";
import { mobile, tablet } from "../../responsive";

const Container = styled.div`
    margin: 5rem;
    overflow-x: hidden;

    ${mobile({
        width: "100%",
        margin: "0"
    })}
`;
const Title = styled.h1`
    font-size: 48px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    letter-spacing: 1px;
    text-shadow: 5px 5px black;

    ${mobile({
        fontSize: "36px",
        margin: "0",
    })}
`;
const Body = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: .5rem;
    padding: 2rem 0rem 0rem;
    align-items: center;
    justify-content: center;
    margin-left: 1.8rem;

    ${tablet({
        gridTemplateColumns: "repeat(2, 1fr)"
    })}

    ${mobile({
        gridTemplateColumns: "repeat(1, 1fr)",
        marginRight: "1rem",
        marginLeft: "1rem"
    })}
`;
const InfoContainer = styled.div`
    min-width: 370px;
    display: flex;
    justify-content: center;
    width: 320px;
    min-height: 210px;
    background: white;
    border-radius: 10px;
    
    ${tablet({
        borderRadius: "10px 30px"
    })}

    ${mobile({
        minWidth: "100%",
        borderRadius: "10px"
    })}
`;
const Icon = styled.div`
    width: 70px;
    height: 70px;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    i{
        border-radius: 50%;
        background-color: darkblue;
        color: white;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
    }
`;
const Facility = styled.div`
    width: 60%;
`;
const Name = styled.h4`
    font-size: 1.2rem;
    padding: 1rem 0rem;
`;
const List = styled.ul`
    font-size: 1rem;
`;
const Item = styled.li`
    line-height: 1.6;
    margin-bottom: .5rem;
`;

const WhyPrimeTech = () => {
  return (
    <Container>
        <Title>WHY PRIME TECH ?</Title>
        <Body>
            <InfoContainer>
                <Icon>
                    <i className="fa-solid fa-person" />
                </Icon>
                <Facility>
                    <Name>CUSTOMER CENTRIC APPROCH</Name>
                    <List>
                        <Item>Program Management capabilities to support customer's new developments.</Item>        
                    </List>
                </Facility>
            </InfoContainer>
            <InfoContainer>
                <Icon>
                    <i className="fa-solid fa-gears" />
                </Icon>
                <Facility>
                    <Name>ADVANCED ENGINEERING CAPABILITIES</Name>
                    <List>
                        <Item>Continued Improvement for the effectiveness and efficiency of our Products and Services.</Item>
                    </List>
                </Facility>
            </InfoContainer>
            <InfoContainer>
                <Icon>
                    <i className="fa-solid fa-thumbs-up" />
                </Icon>
                <Facility>
                    <Name>SOUND MANUFACTURING KNOWLEDGE</Name>
                    <List>
                        <Item>Pneumatic, mechanical and controls expertise.</Item>
                        <Item>Dedicated Robot teaching expert.</Item>
                    </List>
                </Facility>
            </InfoContainer>
            <InfoContainer>
                <Icon>
                    <i className="fa-solid fa-industry" />
                </Icon>
                <Facility>
                    <Name>COMMITMENT</Name>
                    <List>
                        <Item>Top Management Commitment & Direct Involvement.</Item>
                        <Item>Everybody's involvement thru shared vision and commitment.</Item>
                    </List>
                </Facility>
            </InfoContainer>
            <InfoContainer>
                <Icon>
                    <i className="fa-solid fa-clipboard-check" />
                </Icon>
                <Facility>
                    <Name>PROVEN SUCCESS</Name>
                    <List>
                        <Item>We provide customers a defect & hassle-free experience through our design, software program and CMM, in-house.</Item>
                    </List>
                </Facility>
            </InfoContainer>
            <InfoContainer>
                <Icon>
                    <i className="fa-solid fa-link" />
                </Icon>
                <Facility>
                    <Name>TRUST</Name>
                    <List>
                        <Item>Over 20 Years of Experienced Staffs in Automation Field.</Item>
                    </List>
                </Facility>
            </InfoContainer>
        </Body>
    </Container>
  )
}

export default WhyPrimeTech
