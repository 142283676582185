import styled from "styled-components";
import { mobile, tablet } from "../../responsive";

const Container = styled.div`
    background-color: black;
    height: 280px;
    padding: 3rem 6rem;
    color: #ffffff;
    margin: 3rem 0rem;

    ${mobile({
        padding: "1rem",
        marginBottom: "4rem",
        height: "360px"
    })}
`;
const Header = styled.h2`
    font-size: 2rem;
    margin-bottom: 1.5rem;
    letter-spacing: 1px;

    &:after {
        content: "";
        display: block;
        width: 25vw;
        border-top: solid red 2px;
        opacity: .9;

        ${mobile({
            width: "90%"
        })}
        ${tablet({
            width: "52%"
        })}
    }

    ${mobile({
        fontSize: "29px"
    })}
`;
const Body = styled.p`
    font-size: 1.3rem;
    line-height: 1.5;

    ${mobile({
        fontSize: "1.2rem"
    })}
`;
const Span = styled.span`
    color: red;
    font-size: 30px;
`;
const Statement = () => {
  return (
    <Container>
        <Header>
            MISSION STATEMENT
        </Header>
        <Body>
            <Span>“</Span> Bringing together over 100 years of accumulated hands-on experience, 
            Prime Tech provides superior design and delivery of body welding 
            lines for automotive companies, resulting in high-quality automation 
            solutions with predictable time to market. <Span>”</Span>
        </Body>
    </Container>
  )
}

export default Statement
