import React from 'react'
import Header from '../components/Header'
import ServicesHeader from '../components/engineering services/ServicesHeader'
import ServicesContent from '../components/engineering services/ServicesContent'
import Footer from '../components/Footer'
import GoHome from '../components/GoHome'

const EngineeringServices = () => {
  return (
    <div>
      <Header />
      <ServicesHeader />
      <ServicesContent />
      <GoHome />
      <Footer />
    </div>
  )
}

export default EngineeringServices
