import React from 'react'
import Header from '../components/Header';
import ServicesContent from '../components/ServicesContent';
import Footer from '../components/Footer';

const Service = () => {
  return (
    <div>
      <Header />
      <ServicesContent />
      <Footer />
    </div>
  )
}

export default Service
