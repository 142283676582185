import styled from 'styled-components';
import Header from '../Header';
import Footer from '../Footer';
import { migcheckingItem } from '../../data';
import MigChecking from './MigChecking';
import PreviousWorkButton from '../PreviousWorkButton';
import { mobile, tablet } from '../../responsive';

const Container = styled.div`
    margin: 7rem 4rem;

    ${tablet({
        marginLeft: "2.2rem"
    })}

    ${mobile({
        margin: "2rem 0",
        width: "100vw"
    })}
`;

const Content = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    ${tablet({
        gridTemplateColumns: "repeat(3, 1fr)"
    })}

    ${mobile({
        display: "flex",
        flexDirection: "column",
        gridGap: "1rem"
    })}
`;

const MigCheckingFixtures = () => {
  return (
    <>
        <Header />
        <Container>
            <Content>
                {migcheckingItem.map( (item) => {
                    return (
                        <MigChecking item={item} key={item.id} />
                    )
                })}
            </Content>
        </Container>
        <PreviousWorkButton />
        <Footer />
    </>
  )
}

export default MigCheckingFixtures
