import React from 'react'
import Header from '../components/Header'
import BodyWeldingHeader from '../components/body welding/BodyWeldingHeader'
import BodyWeldingContent from '../components/body welding/BodyWeldingContent'
import styled from 'styled-components'
import Footer from '../components/Footer'
import GoHome from '../components/GoHome'

const Container = styled.div ``;
const BodyWelding = () => {
  return (
    <Container>
      <Header />
      <BodyWeldingHeader />
      <BodyWeldingContent />
      <GoHome />
      <Footer />
    </Container>
  )
}

export default BodyWelding
