import React from 'react'
import Header from '../components/Header'
import MaterialHandlingHeader from '../components/material handling/MaterialHandlingHeader';
import MaterialHandlingContent from '../components/material handling/MaterialHandlingContent';
import Footer from '../components/Footer';
import GoHome from '../components/GoHome';

const MaterialHandling = () => {
  return (
    <div>
      <Header />
      <MaterialHandlingHeader />
      <MaterialHandlingContent />
      <GoHome />
      <Footer />
    </div>
  )
}

export default MaterialHandling
