import { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import SearchBar from './SearchBar';
import { mobile, tablet } from '../responsive';
import './Navbar.css';

const Container = styled.div`
    overflow-x: hidden;
`;
const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.6rem 3%;
    box-shadow: 0 1rem 1rem rgb(0, 0, 0.1);
    height: 50px;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    z-index: 9999;
    top: ${(props) => (
        props.fix === false ? "130px" : "0px"
    )};
    width: ${(props) => (
        props.fix === false ? "95%" : "100vw"
    )};
    border-radius: ${(props) => (
        props.fix === false ? "3rem" : "0rem"
    )};
    transition: .5s ease-in-out;
    background-image: linear-gradient( 174.2deg,  rgba(255,244,228,1) 7.1%, rgba(240,246,238,1) 67.4% );

    ${tablet({
        zIndex: "99",
    })}

    ${mobile({
        minHeight: "40px",
    })}
`;

const Left = styled.div`
    flex: 1;
    width: 350px;
    display: flex;
`;

const Right = styled.div`
    flex: 4;
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: .8rem;
    margin-left: 3rem;

    ${tablet({
        display: "flex", 
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "#fff",
        width: "80%",
        height: "auto",
        position: "absolute", 
        top: "110%",
        alignItems: "stretch", 
        zIindex: "-1",
        transition: ".3s ease-in-out"
    })}
`;

const Heading = styled(NavLink)`
    padding: .7rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    transition: all .3s ease-in-out;
    width: 125px;
    color: black;
    text-decoration: none;
    border-radius: 30px;
    font-weight: 500;
    text-shadow: .1px 0px black;
    z-index: 1000;
    
    &:hover{
        background-color: #153391;
        color: #fff;
    }
    &.active{
        background: #153391;
        color: white;
        box-shadow: 1px 2px 5px black;
    }

    ${tablet({
        width: "90%",
        margin: "auto"
    })}
    ${mobile({
        width: "90%",
        margin: "auto",
    })}
`;

const Center = styled.div`
    display: none;

    ${tablet({
        display: "flex",
        cursor: "pointer",
        fontSize: "1.8rem",
        color: "black",
        marginRight: "1rem"
    })}
`;

const Navbar = () => {
    const [ fix, setFix ] = useState(false);
    const [ clicked, setClicked ] = useState(false); 

    const onScroll = () =>{
        if(window.scrollY > 10){
            setFix(true);
        }
        else if(window.scrollY < 120){
            setFix(false);
        }
    }

    const handleClick = () =>{
        setClicked( !clicked);
    }

    window.addEventListener("scroll", onScroll);
  return (
    <Container>
        <Wrapper fix={fix}>
            <Left>
                <SearchBar />
            </Left>
            <Center onClick={handleClick}>
                <i className={clicked ? "fas fa-times" :
                "fas fa-bars"}></i>
            </Center>
            <Right className={clicked ? "nav-menu active" 
            : "nav-menu"}>
                <Heading to="/" onClick={handleClick}>
                    WORKS
                </Heading>
                <Heading to="/home" onClick={handleClick}>
                    HOME
                </Heading>    
                <Heading to="/service" onClick={handleClick}>
                    SERVICES
                </Heading>
                <Heading to="/about" onClick={handleClick}>
                   ABOUT US
                </Heading>
                <Heading to="/contact" onClick={handleClick}>
                    CONTACT
                </Heading>
            </Right>
        </Wrapper>
    </Container>
  )
}

export default Navbar
