import Home from "./pages/Home";
import About from "./pages/About";
import Works from "./pages/Works";
import ContactPage from "./pages/ContactPage";
import Service from "./pages/Service";
import './Styles.css';
import { Routes, Route } from 'react-router-dom';
import BodyWelding from "./pages/BodyWelding";
import EngineeringServices from "./pages/EngineeringServices";
import PrototypeSupport from "./pages/PrototypeSupport";
import MaterialHandling from './pages/MaterialHandling';
import Innovation from './pages/Innovation';
import CarSeatFrame from "./components/CarSeatFrame";
import TrolleyPallets from "./components/trolleypallets/TrolleyPallets";
import Migs from "./components/mig fixture/MigFixture";
import Rivetings from "./components/riveting fixture/RivetingFixture";
import Assembles from "./components/assemble fixture/AssembleFixture";
import Spots from "./components/spot fixture/SpotFixture";
import MigCheckingFixtures from "./components/mig checking fixture/MigCheckingFixture";
import Navbar from "./components/Navbar";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/home" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/" element={<Works />}></Route>
        <Route path="/contact" element={<ContactPage />}></Route>
        <Route path="/service" element={<Service />}></Route>
        <Route path="/body-welding" element={<BodyWelding />}></Route>
        <Route path="/engineering-services" element={<EngineeringServices />}></Route>
        <Route path="/prototype-support" element={<PrototypeSupport />}></Route>
        <Route path="/material-handling" element={<MaterialHandling />}></Route>
        <Route path="/innovation" element={<Innovation />} ></Route>
        <Route path="/car-seat-frame" element={<CarSeatFrame />}></Route>
        <Route path="/trolley-pallets" element={<TrolleyPallets />}></Route>
        <Route path="/mig" element={<Migs />}></Route>
        <Route path="/riveting" element={<Rivetings />}></Route>
        <Route path="/assemble" element={<Assembles />}></Route>
        <Route path="/spot" element={<Spots />}></Route>
        <Route path="/mig-checking" element={<MigCheckingFixtures />}></Route>
      </Routes>
    </div>
  );
}

export default App;
