import styled from "styled-components";
import { mobile } from "../responsive";

const Container = styled.div`
    color: white;
    display: flex;
    flex-direction: column;
    margin: 10rem 0rem 5rem;
    overflow-x: hidden;

    ${mobile({
        margin: "5rem 0",
        width: "100vw",
    })}
`;

const Title = styled.h1`
    padding: 0.8rem 1.5rem;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.6; 

    ${mobile({
        fontSize: "1.1rem"
    })}
`;
const List = styled.ul`
    padding: 0rem 5rem;
    font-size: 1rem;
    line-height: 2.1;

    ${mobile({
        fontSize: "1rem"
    })}
`;
const Item = styled.li`
    ${mobile({
        marginLeft: "-20%"
    })}
`;

const InfoContainer = styled.div`
    width: 70vw;
    margin: auto;
    margin-top: 2rem;
    background-color: #fff;
    color: black;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    border-radius: 15px;

    ${mobile({
        width: "95%",
        borderRadius: "5px"
    })}
`;

const ImageTop = styled.div`
    height: 40vh;
    background: linear-gradient(
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.7)
    ),
    url("https://as1.ftcdn.net/v2/jpg/03/92/97/26/1000_F_392972690_ScOsgGnQqAWc3g5rIunHbfiXH8FSzNsH.jpg");
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.7rem;
    color: white;
    padding-top: 16rem;
    border-radius: 15px 15px 0px 0px;

    ${mobile({
        paddingLeft: "1rem",
        textShadow: "2px 2px black",
        borderRadius: "5px 5px 0 0", 
        fontSize: "1.5rem",
        margin: "auto",
        padding: "0 .5rem"
    })}
`;

const ImageBottom = styled.div`
    height: 40vh;
    background: linear-gradient(
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.7)
    ),
    url("https://images.pexels.com/photos/1181396/pexels-photo-1181396.jpeg?auto=compress&cs=tinysrgb&w=600");
    background-position: center;
    background-size: cover; 
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.7rem;
    color: white;
    padding-top: 16rem;
    border-radius: 15px 15px 0px 0px;

    ${mobile({
        textShadow: "2px 2px black",
        borderRadius: "5px 5px 0 0",
        padding: "0"
    })}
`;

const ServicesContent = () => {
  return (
    <Container>
        <InfoContainer>
            <ImageTop>
                Complete One Stop Solution for Customer
            </ImageTop>
            <Title>Solution to Customer as well as In-House Production Facility
                    <br></br> Our Specialization are:
            </Title>
            <List>
                <Item>Design & Development</Item>
                <Item>Process Engineering</Item>
                <Item>All Type of Welding Technology</Item>
                <Item>All type of Automation & Material Handling</Item>
                <Item>Special Cutting processes</Item>
            </List>
        </InfoContainer>
        <InfoContainer>
            <ImageBottom>
                Who We Are
            </ImageBottom>
            <Title>We firmly known as welding and total automation solution provider</Title>
            <List>
                <Item>Welding Line, Assemble line, Auto Handling, Gentries, Production and testing SPM's etc.</Item>
                <Item>Manufacturing of Precision sheet metal components , Tubular Parts/ Assemblies for automotive OEMs , Heavy Fabrication Parts & Assemblies for Construction & Agriculture Industry.</Item>
            </List>
        </InfoContainer>
    </Container>
  )
}

export default ServicesContent
