import styled, { keyframes } from "styled-components";
import { mobile, tablet } from "../../responsive";

const Container = styled.div`
    margin-top: -3rem;
    overflow-x: hidden;
    ${tablet({
        margin: "0 1rem",
    })}
    ${mobile({
        margin: "0",
        marginTop: "-12rem",
        width: "100vw",
    })}
`;
const Title = styled.h2`
    font-size: 48px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-bottom: 1.5rem;
    letter-spacing: 1px;
    text-shadow: 5px 5px black;

    ${mobile({
        fontSize: "33px",
        margin: "0",
    })}
`;
const Body = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: space-around;
    padding-left: 1.8rem;
    grid-gap: .5rem;
    ${tablet({
        gridTemplateColumns: "repeat(2, 1fr)",
    })}

    ${mobile({
        display: 'flex',
        flexDirection: 'column',
        margin: '0',
        padding: '0',
        margin: '3rem 0'
    })}
`;
const InfoContainer = styled.div`
    display: flex;
    min-width: 350px;
    min-height: 280px;
    background: white;
    border-radius: 10px;
    margin: auto;
    margin: .1rem;
    padding: 1rem .7rem;
    width: 390px;
    ${tablet({
        borderRadius: "10px 30px"
    })}

    ${mobile({
        borderRadius: "10px",
        width: '95vw',
    })}
`;
const Icon = styled.div`
    width: 70px;
    height: 70px;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    i{
        border-radius: 50%;
        background-color: darkblue;
        color: white;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
    }
`;
const Facility = styled.div`
    width: 60%;
`;
const Name = styled.h4`
    font-size: 1.2rem;
    margin-top: 1.1rem;
`;
const List = styled.ul`
    font-size: 1rem;
    margin-top: 1rem;
`;
const Item = styled.li`
    line-height: 1.6;
    margin-bottom: .5rem;
`;

const Facilities = () => {
  return (
    <Container>
        <Title>IN HOUSE FACILITIES</Title>
        <Body>
            <InfoContainer>
                <Icon>
                    <i className="fa-solid fa-people-group" />
                </Icon>
                <Facility>
                    <Name>Dedicated Design Team</Name>
                    <List>
                        <Item>We have in house dedicated design team for mechanical design.</Item>
                        <Item>Fixture design, inspection gauges, cells, SPM, Automation lines.</Item>
                    </List>
                </Facility>
            </InfoContainer>
            <InfoContainer>
                <Icon>
                    <i className="fa-solid fa-industry" />
                </Icon>
                <Facility>
                    <Name>Manufacturing Facility</Name>
                    <List>
                        <Item>Along with 40 vendor base, we have in house manufacturing facility.</Item>
                        <Item>Planno, Jig Boring, M1TR, Milling, Drilling, Lathes, Welding, etc.</Item>
                    </List>
                </Facility>
            </InfoContainer>
            <InfoContainer>
                <Icon>
                    <i className="fa-solid fa-star" />
                </Icon>
                <Facility>
                    <Name>Quality Control</Name>
                    <List>
                        <Item>Flying scot for ensuring quality parts supplies.</Item>
                        <Item>In-house gauging facility.</Item>
                        <Item>n-house CMM (Romer/ Faro).</Item>
                    </List>
                </Facility>
            </InfoContainer>
            <InfoContainer>
                <Icon>
                    <i className="fa-solid fa-terminal" />
                </Icon>
                <Facility>
                    <Name>Dedicated Programming Team</Name>
                    <List>
                        <Item>We have in house dedicated PLC programming team, can handle any make PLC, including SCADA.</Item>
                        <Item>FVision system programming, integrations.</Item>
                    </List>
                </Facility>
            </InfoContainer>
            <InfoContainer>
                <Icon>
                    <i className="fa-solid fa-briefcase" />
                </Icon>
                <Facility>
                    <Name>Project Management</Name>
                    <List>
                        <Item>Program management team to track the project activities.</Item>
                        <Item>Well trained assembly fitters & supervisors.</Item>
                    </List>
                </Facility>
            </InfoContainer>
            <InfoContainer>
                <Icon>
                    <i className="fa-solid fa-user-check" />
                </Icon>
                <Facility>
                    <Name>Customer Service</Name>
                    <List>
                        <Item>Well trained customer support team to ensure the installation & commissioning and provide customer support, ALL TIME.</Item>
                    </List>
                </Facility>
            </InfoContainer>
        </Body>
    </Container>
  )
}

export default Facilities
