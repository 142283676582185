import styled from 'styled-components';
import Header from '../Header';
import { rivetingItem } from '../../data';
import Footer from '../Footer';
import Riveting from './Riveting';
import PreviousWorkButton from '../PreviousWorkButton';
import { mobile, tablet } from '../../responsive';

const Container = styled.div`
    margin: 7rem 10rem;

    ${mobile({
        margin: "0", 
        width: "100vw"
    })}
`;

const Content = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    ${tablet({
        gridTemplateColumns: "repeat(2, 1fr)"
    })}

    ${mobile({
        display: "flex",
        flexDirection: "column", 
        gridGap: "1rem"
    })}
`;

const Migs = () => {
  return (
    <>
        <Header />
        <Container>
            <Content>
                {rivetingItem.map( (item) => {
                    return (
                        <Riveting item={item} key={item.id} />
                    )
                })}
            </Content>
        </Container>
        <PreviousWorkButton />
        <Footer />
    </>
  )
}

export default Migs
