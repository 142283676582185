import React from 'react';
import { Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { EffectCoverflow, Pagination, Navigation } from 'swiper';
import './SliderStyles.css';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { mobile } from '../responsive';

const Box = styled.div`
    background-color: #fff;
    color: #222;
    width: 80%;
    height: 35rem;
    display: flex;
    flex-direction: column;
  
    ${mobile({
      width: "100%",
      height: "24rem"
    })}
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0rem;

  ${mobile({
    padding: "1rem 0"
  })}
`;

const Image = styled.img`
    height: 350px;
    background-size: cover;

    ${mobile({
      height: "250px"
    })}
`;

const Type = styled.h4`
    font-size: 2rem;
    color: darkblue;
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    ${mobile({
      fontSize: "1.1rem"
    })}
`;

const About = styled.p`
    color: #222;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 1.5rem;
    font-size: 1.1rem;

    ${mobile({
      display: "none"
    })}
`;

const Button = styled(Link)`
    text-decoration: none;
    display: flex;
    justify-content: center;
    color: #fff;
    background-color: red;
    width: 150px;
    margin: auto;
    font-size: 1.4rem;
    padding: .7rem;
    margin-bottom: 2rem;

    ${mobile({
      marginTop: "1rem",
      fontSize: "1.2rem",
      padding: ".5rem .1rem",
    })}
`;

function Slider() {
  return (
    <div className="container">
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="swiper_container"
      >
        <SwiperSlide>
          <Box>
            <Image src="https://images.pexels.com/photos/2480481/pexels-photo-2480481.jpeg?auto=compress&cs=tinysrgb&w=600" />
            <Info>
              <Type>BODY WELDING SOLUTIONS</Type>
              <About>Turnkey solution provider in BIW Body shop projects</About>
              <Button to="/body-welding" >Read more</Button>
            </Info>
          </Box>        
        </SwiperSlide>
        <SwiperSlide>
          <Box>
            <Image src="https://images.pexels.com/photos/3825584/pexels-photo-3825584.jpeg?auto=compress&cs=tinysrgb&w=600" />
            <Type>ENGINEERING SERVICES</Type>
            <About>Turnkey engineering services from Process Planning to PLC Offline Program</About>
            <Button to="/engineering-services">Read more</Button>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box>
            <Image src="https://images.pexels.com/photos/1050312/pexels-photo-1050312.jpeg?auto=compress&cs=tinysrgb&w=600" />
            <Type>PROTOTYPE SUPPORT</Type>
            <About>Delivering complete solution for prototype bodies</About>
            <Button to="/prototype-support">Read more</Button>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box>
            <Image src="https://as1.ftcdn.net/v2/jpg/02/57/06/16/1000_F_257061628_Uo3wfAev3EVaKPgmJbfJVjRQmvSOfWMz.jpg" />
            <Type>MATERIAL HANDLING</Type>
            <About>Automatic transfering of the underbody and the main body</About>
            <Button to="/material-handling">Read more</Button>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box>
            <Image src="https://as2.ftcdn.net/v2/jpg/03/88/05/27/1000_F_388052757_z9CmtRGBs7xy1hOFkHH0jj4vmRFZSCBH.jpg" />
            <Type>INNOVATION</Type>
            <About>Innovative automation offers a more advanced and organized future</About>
            <Button to="/innovation">Read more</Button>
          </Box>
        </SwiperSlide>

        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <i className="fa-solid fa-angle-left" />
          </div>
          <div className="swiper-button-next slider-arrow">
            <i className="fa-solid fa-angle-right" />
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
    </div>
  );
}

export default Slider;