import styled from 'styled-components';
import { mobile, tablet } from '../../responsive';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';

const Container = styled.div`
    background-color: white;
    padding: 4rem;
    margin: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${mobile({
        margin: "1rem .3rem"
    })}
`;
const Heading = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
`;
const Title = styled.h2`
    font-size: 28px;
    margin-bottom: 1rem;
`;
const Form = styled.form`
    display: flex;
    flex-direction: column;

    ${tablet({
        margin: "0rem 4rem",
    })}
`;

const FormContent = styled.div`
    display: flex;

    ${tablet({
        flexDirection: "column",
        width: "50vw",
        justifyContent: "center",
    })}

    ${mobile({
        flexDirection: 'column',
        alignItems: "center",
        justifyContent: "center",
    })}
`;

const Left = styled.div`
    margin-right: 2rem;

    ${mobile({
        margin: "0rem",
        width: "90vw",
        justifyContent: "center",
        alignItems: "center"
    })}
`;
const Right = styled.div`
    ${mobile({
        margin: "0rem",
        width: "90vw"
    })}
`;

const Text = styled.p`
    font-size: 16px;

    ${mobile({
        width: "90vw"
    })}
`;
const Input = styled.input`
    margin: .8rem 0;
    width: 400px;
    height: 40px;
    font-size: 16px;
    padding: 0 .5rem;
    ${tablet({
        border: ".5px solid black"
    })}

    ${mobile({
        margin: "1rem 0",
        width: "95%"
    })}
`;
const TextArea = styled.textarea`
    width: 400px;
    height: 208px;
    margin: .8rem 0;
    font-size: 16px;
    padding: .5rem .5rem;

    ${tablet({
        border: ".5px solid black"
    })}
    ${mobile({
        width: "95%"
    })}
`;
const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: #CE2C3C;
    color: white;
    border: none;
    width: 90px;
    font-size: 1.1rem;
    margin-top: 1.5rem;
    border-radius: 50px;
    transform:.8s ease all;

    &:hover{
        pointer: cursor;
        transform: scale(.9);
    }
    ${mobile({
        margin: "auto"
    })}
`;
const ContactForm = () => {
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ company, setCompany ] = useState('');
    const [ subject, setSubject ] = useState('');
    const [ message, setMessage ] = useState('');

    const form = useRef();

    const sendEmail = (event) => {
        event.preventDefault();
        emailjs.sendForm(
            'service_26j9mv8',
            'template_wef6wr8',
            form.current,
            'f_PqLZ0QJUSaoJeVf'
        )
        .then( (result) => {
            console.log(result.text);
            setName('');
            setEmail('');
            setPhone('');
            setCompany('');
            setSubject('');
            setMessage('');
            notify();
        }, (error) => {
            console.log(error.text);
        });
    };

    const notify = () => {
        toast.success('Message is sent!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
    };


  return (
    <Container>
        <Heading>
            <Title>Get in touch</Title>
            <Text>Please fill out the form below and we shall respond to all queries.</Text>
        </Heading>
        <Form ref={form} onSubmit={sendEmail}>
            <FormContent>
                <Left>
                    <Text>Your Name (required)</Text>
                    <Input 
                    name='from_name'
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    />
                    <Text>Your Email (required)</Text>
                    <Input 
                    name='from_email'
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    />
                    <Text>Your Phone Number</Text>
                    <Input 
                    name='phone_number'
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                    />
                    <Text>Company Name</Text> 
                    <Input 
                    name='company_name'
                    value={company}
                    onChange={(event) => setCompany(event.target.value)}
                    />
                </Left>
                <Right>
                    <Text>Subject</Text>
                    <Input 
                    placeholder='Let us know your topic of interest..!'
                    name='subject'
                    value={subject}
                    onChange={(event) => setSubject(event.target.value)}
                    />
                    <Text>Your Message</Text>
                    <TextArea 
                    name='message'
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                    />
                </Right>
            </FormContent>
        <Button>Send</Button>
        <ToastContainer />
        </Form>
    </Container>
  )
}

export default ContactForm
