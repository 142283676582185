import styled from "styled-components";
import { mobile } from "../../responsive";

const Container = styled.div`
    color: white;
    display: flex;
    flex-direction: column;
    margin: 10rem 0rem 5rem;
    overflow-x: hidden;
`;

const Title = styled.h1`
    padding: 0.8rem 1.5rem;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.6; 

    ${mobile({
        padding: "0.3rem 0.5rem",
        display: "flex",
        alignItems: "center",
        fontSize: "1.3rem"
    })}
`;
const List = styled.ul`
    padding: 0rem 5rem;
    font-size: 1rem;
    line-height: 2.1;

    ${mobile({
        padding: "0rem 2rem"
    })}
`;
const Item = styled.li``;

const Bottom = styled.div`
    width: 90vw;
    margin: auto;
    background-color: #fff;
    color: black;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    border-radius: 15px;

    ${mobile({
        borderRadius: "5px"
    })}
`;

const ImageBottom = styled.div`
    height: 40vh;
    background: linear-gradient(
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.3)
    ),
    url("https://t4.ftcdn.net/jpg/03/36/83/91/240_F_336839140_WtikFfGozTUWpmk4oxchd8Lcewt5VHCs.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.9rem;
    color: white;
    padding-top: 16rem;
    border-radius: 15px 15px 0px 0px;

    ${mobile({
        fontSize: "2.1rem",
        textShadow: "2px 2px black",
        borderRadius: "5px 5px 0 0"
    })}
`;

const AboutUsTeam = () => {
  return (
    <Container>
        <Bottom>
            <ImageBottom>
                Our Team
            </ImageBottom>
            <Title>Over 20 Years of Experienced Staff in Automation Field Top Management Commitment</Title>
            <List>
                <Item>Team of 100 + Qualified Skilled Staff & Associates.</Item>
                <Item>Exclusive in-house design Team Mechanical as well as Electrical.</Item>
                <Item>Development & Process Engineering Team.</Item>
                <Item>Dedicated Customer Care & Service Team.</Item>
                <Item>Dedicated Production & Quality Assurance team.</Item>
                <Item>Standard Room Facilitated with State of the Art Equipment's.</Item>
                <Item>100 % Power back-up.</Item>
            </List>
        </Bottom>
    </Container>
  )
}

export default AboutUsTeam
