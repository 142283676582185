import React from 'react'
import Header from '../components/Header'
import InnovationHeader from '../components/innovation/InnovationHeader'
import InnovationContent from '../components/innovation/InnovationContent'
import Footer from '../components/Footer'
import GoHome from '../components/GoHome'

const Innovation = () => {
  return (
    <div>
      <Header />
      <InnovationHeader />
      <InnovationContent />
      <GoHome />
      <Footer />
    </div>
  )
}

export default Innovation
