import AboutUsContent from '../components/about us/AboutUsContent';
import Footer from '../components/Footer';
import Header from '../components/Header';
import AboutUs from '../components/about us/AboutUs';
import AboutUsTeam from '../components/about us/AboutUsTeam';
import AboutUsCopy from '../components/about us/AboutUsCopy';

const Home = () => {
  return (
    <div>
      <Header />
      <AboutUsTeam />
      <AboutUs />
      <AboutUsContent />
      <Footer />
    </div>
  )
}

export default Home
