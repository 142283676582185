import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import styled from 'styled-components'
import { palletsItem } from '../../data'
import TrolleyPallet from './TrolleyPallet'
import PreviousWorkButton from '../PreviousWorkButton'
import { mobile, tablet } from '../../responsive'

const Container = styled.div`
    margin: 7rem 4rem;

    ${tablet({
        marginLeft: "2.2rem"
    })}

    ${mobile({
        margin: "0",
    })}
`;

const Content = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    ${mobile({
        display: "flex",
        flexDirection: "column",
        margin: "0",
        gridGap: "1rem"
    })}

    ${tablet({
        gridTemplateColumns: "repeat(3, 1fr)"
    })}
`;

const TrolleyPallets = () => {
  return (
    <>
        <Header />
        <Container>
            <Content>
                {palletsItem.map( (item) =>{
                    return(
                        <TrolleyPallet item={item} key={item.id} />
                    )
                })}
            </Content>
        </Container>
        <PreviousWorkButton />
        <Footer />
    </>
  )
}

export default TrolleyPallets
