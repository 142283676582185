import Header from '../components/Header';
import WorksHeader from '../components/WorksHeader';
import Footer from '../components/Footer';
import Work from '../components/Work';
import WorkTeam from '../components/WorkTeam';

const Works = () => {
  return (
    <div>
      <Header />
      <WorkTeam />
      <WorksHeader />
      <Work />
      <Footer />
    </div>
  )
}

export default Works
