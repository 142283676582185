import ContactHeader from '../components/contact/ContactHeader';
import Header from '../components/Header';
import Footer from '../components/Footer';
import React from 'react'
import Contact from '../components/contact/Contact';
import ContactForm from '../components/contact/ContactForm';

const ContactPage = () => {
  return (
    <div>
        <Header />
        <ContactHeader />
        <ContactForm />
        <Contact />
        <Footer />
    </div>
  )
}

export default ContactPage
