import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { mobile, tablet } from '../responsive';

const Container = styled.div`
    background-image: linear-gradient( 58.2deg,  rgba(40,91,212,0.73) -3%, rgba(171,53,163,0.45) 49.3%, rgba(255,204,112,0.37) 97.7% );

`;

const Top = styled.div`
    display: flex;
    color: white;
    font-size: 14px;
    height:400px;
    padding-top: 30px;

    ${mobile({
    flexDirection: "column",
    height: "1070px"
})}
`;

const Bottom = styled.div`
    color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 1.3rem 0;
    font-size: 1.1rem;

    ${mobile({
    textAlign: "center",
    display: "flex",
    flexDirection: "column"
})}
`;

const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;

    ${mobile({
    alignItems: "center"
})}
`;

const Logo = styled.h1`
    font-size: 2.8rem;
    background-image: linear-gradient(to right, white, violet);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;

    ${tablet({
    fontSize: "2.2rem"
})}

`;

const Desc = styled.p`
    margin: 20px 0;
    line-height: 1.5;
    font-size: .9rem;

    ${tablet({
    fontSize: "1.1rem"
})}
`;

const SocialContainer = styled.div`
    display: flex;
    a{
        text-decoration: none;
    }
`;

const SocialIcon = styled.i`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    color: white;
    margin-right: 10px;
    transition: all .2s ease; 

    &:hover i{
        transform: scale(1.1);
    }

    ${tablet({
    marginRight: "0px"
})}
`;

const Center = styled.div`
    flex: 1;
    padding: 20px;     
`;

const Title = styled.h3`
    margin-bottom: 20px;
    font-size: 1.8rem;
    display: flex;

    ${tablet({
    fontSize: "1.2rem"
})}

    ${mobile({
    fontSize: "2.2rem",
    marginLeft: "25%"
})}
`;

const List = styled.ul`
    margin-top: 1rem;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ListItem = styled.li`
    width: 50%;
    margin-bottom: 20px;
    font-size: 1rem;
    transition: all .2s ease;

    ${tablet({
    fontSize: ".9rem"
})}
    
    &:hover{
        padding-left: 5px;
        color: orange;
        cursor: pointer;
    }
    i{
        font-size: 1.3rem;
        margin-right: 7px;
        background-color: white;
        color: black;
        height: 25px;
        width: 25px;
        padding: 3px;
        border-radius: 50%;
    }

    ${mobile({
    fontSize: "1.2rem"
})}
`;

const Path = styled(Link)`
    text-decoration: none;
    color: white;
`;

const Right = styled.div`
    flex: 1;
    padding: 20px;

    ${mobile({
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center"
})}
`;

const ContactItem = styled.p`
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-size: .9rem;
    line-height: 1.5;

    i{
        font-size: 1rem;
        margin-right: 7px;
        background-color: white;
        color: black;
        height: 28px;
        width: 28px;
        padding: 5px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    ${tablet({
    fontSize: "1.1rem"
})}

    ${mobile({
    fontSize: "1.1rem",
    alignItems: "start",
})}
`;

const A = styled.a`
text-decoration: none;
color: blue;
&:hover {
    color:white;
}
`

const Footer = () => {
    return (
        <Container>
            <Top>
                <Left>
                    <Logo>PRIME TECH.</Logo>
                    <Desc>
                        Prime Tech enterprises leverages world-class automotive body welding design and engineering capabilities, with proven hands-on expertise, and advanced weld assembly line automation solutions.
                    </Desc>
                    <SocialContainer>
                        <a href="https://wa.me/+919884538205" target="_blank"><SocialIcon className='fa-brands fa-whatsapp' /></a>
                    </SocialContainer>
                </Left>
                <Center>
                    <List>
                        <ListItem><i className="fa-solid fa-arrow-right"></i><Path to="/home">HOME</Path></ListItem>
                        <ListItem><i className="fa-solid fa-arrow-right"></i><Path to="/about">ABOUT US</Path></ListItem>
                        <ListItem><i className="fa-solid fa-arrow-right"></i><Path to="/service">SERVICES</Path></ListItem>
                        <ListItem><i className="fa-solid fa-arrow-right"></i><Path to="/">WORK</Path></ListItem>
                        <ListItem><i className="fa-solid fa-arrow-right"></i><Path to="/contact">CONTACT US</Path></ListItem>
                    </List>
                </Center>
                <Right>
                    <Title>CONTACT</Title>
                    <ContactItem>
                        <i class="fa-solid fa-location-dot" style={{ marginRight: "10px" }}></i>
                        No-36/19 <br></br>
                        Pillayar Koil 4th Street Ekkattuthangal-600032
                    </ContactItem>
                    <ContactItem>
                        <i class="fa-solid fa-phone" style={{ marginRight: "10px" }}></i>
                        +91 9884538205
                    </ContactItem>
                    <ContactItem>
                        <i class="fa-solid fa-envelope" style={{ marginRight: "10px" }}></i>
                        designprimetech@gmail.com
                    </ContactItem>
                </Right>
            </Top>
            <Bottom>
                @2023 Prime Tech. All Rights Reserved. Designed by &nbsp;
                <A
                    href='https://www.interdeccaan.in/'
                    target='_blank'
                >
                  Inter Deccaan Solutions
                </A>
            </Bottom>
        </Container>
    )
}

export default Footer
