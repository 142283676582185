import styled from "styled-components";
import Img from '../Assets/Team Photo.jpg';
import { mobile } from "../responsive";

const Container = styled.div`
    color: white;
    display: flex;
    flex-direction: column;
    margin: 7rem 0rem 2rem;
    overflow-x: hidden;

    ${mobile({
        margin: 'auto',
        height: '40vh',
        marginBottom: '1rem',
    })}
`;

const Slide = styled.div`
    width: 90vw;
    margin: auto;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 7rem;
    border-radius: 15px;
    overflow: hidden;

    img {
        width: 100%;
        height: 80vh;
        object-fit: contain-box;
        border-radius: 10px;
        z-index: -1;

        ${mobile({
            objectFit: 'contain',
            height: '100%'
        })}
    }

    ${mobile({
        height: '30vh',
    })}
`;

const Info = styled.p`
    position: absolute;
    font-size: 1.3rem;
    margin-top: 200px;
    margin-top: ${(props) => (props.type) === "second" && "250px"};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${(props) => (props.type) === "team" && "2.8rem"};
    letter-spacing: 0.5px;
    backdrop-filter: blur(3px) saturate(130%);
    background-color: rgba(255, 255, 255, 0);
    z-index: 1;

    ${mobile({
        display: "none",
        margin: "auto"
    })}
`;

const MobileInfo = styled.div`
    display: none;

    ${mobile({
        display: "flex",
        fontSize: "1rem",
        fontWeight: "200",
        width: "90vw",
        margin: 'auto',
    })}
`;

const AboutUsTeam = () => {
  return (
    <Container>
        <Slide>
            <img alt="dummy" src={Img} />
            <Info>We are India based company founded with an objective of</Info>
            <Info type="second">"Contemporary world class standards in Welding and Line Automation"</Info>
        </Slide>
        <MobileInfo>
            "We are India based company founded with an objective of 
            Contemporary world class standards in Welding and Line Automation."
        </MobileInfo>
    </Container>
  )
}

export default AboutUsTeam
