import styled from 'styled-components';
import { mobile } from '../../responsive';
const Container = styled.div`
    background: linear-gradient(
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.5)
    ), 
    url("https://t4.ftcdn.net/jpg/04/81/72/77/240_F_481727770_1u2ylNpie8WRosMVbv1COXDnnEK6ofwh.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${mobile({
        height: "250px"
    })}
`;
const Text = styled.div`
    font-size: 3rem;
    color: white;
    margin-top: 2.5rem;
`;
const Span = styled.span`
    color: red;
    font-weight: 600;
`

const ContactHeader = () => {
  return (
    <Container>
        <Text>
            CONTACT US
        </Text>
    </Container>
  )
}

export default ContactHeader
