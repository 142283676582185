import styled from 'styled-components';
import Img1 from '../Assets/Picture1.jpg';
import { mobile, tablet } from '../responsive';

const Container = styled.div`
    height: 9rem;
    width: 100%;
    color:  white;
    background-color: black;

    ${tablet({
        height: "10rem"
    })}

    ${mobile({
        height: "11rem"
    })}
`;

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Left = styled.div`
    flex: 1;

    ${tablet({
        display: "none"
    })}

    ${mobile({
        display: "none"
    })}
`;

const ImgContainer = styled.div`
    height: 100%;
    margin-right: 1rem;

    ${tablet({
        display: "flex",
        alignItems: "center"
    })}

    ${mobile({
        display: "flex",
        alignItems: "center",
        margin: "0"
    })}
`;

const Image = styled.img`
    object-fit: cover;
    height: 70px;
    width: 70px;
    border-radius: 50%;

    ${mobile({
        height: "60px",
        width: "60px"
    })}
`;

const InfoContainer = styled.div`
    color: #fff; 
    letter-spacing: 1px;
    font-family: "Playfair Display", serif; 
    font-weight: 500;
    font-size: 4rem;
    background-image: linear-gradient(to right, white, teal);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;

    ${tablet({
        fontSize: "3rem",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        fontWeight: "600"
    })}

    ${mobile({
        fontSize: "2.5rem",
        fontWeight: "600",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    })}
`;

const Span = styled.span`
    font-family: "Playfair Display", serif; 
    color: ${(props) => (props.type) === "sub" && "white"};
    font-weight: ${(props) => (props.type) === "sub" && "normal"};
    font-size: ${(props) => (props.type) === "sub" && "2.5rem"};

    ${tablet({
        marginLeft: ".5rem",
        fontSize: "3rem", 
        fontWeight: "100"
    })}

    ${mobile({
        display: "none",
    })}

`;

const Center = styled.div`
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: center;

    ${tablet({
        flex: "6",
        height: "100%",
        width: "100%",
        marginLeft: ".5rem"
    })}

    ${mobile({
        flex: "3",
        height: "100%",
        width: "100%",
    })}

`;

const Right = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    ${mobile({
        display: "none"
    })}
`;


const Header = () => {
  return (
    <Container>
        <Wrapper>
            <Left></Left>
            <Center>
                <ImgContainer>
                    <Image src={Img1} alt="image" />
                </ImgContainer>
                <InfoContainer>
                    PRIME TECH
                    <Span type="sub"> ENTERPRISES</Span>
                </InfoContainer>
            </Center>
            <Right></Right>
        </Wrapper>
    </Container>
  )
}

export default Header
