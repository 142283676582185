import styled from 'styled-components';
import Slider from '../Slider';
import { mobile } from '../../responsive';

const Title = styled.h2`
    font-size: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-bottom: 1.5rem;
    letter-spacing: 1px;

    ${mobile({
      margin: "0", 
      fontSize: "34px"
    })}
`;

const Info = styled.p`
    color: #ffffff;
    display: flex;
    justify-content: center;
    padding: 0 6rem;
    font-size: 1.3rem;
    line-height: 1.5;

    ${mobile({
      padding: "1rem",
      fontSize: "1.2rem",
      paddingLeft: "2rem",
    })}
`;


const Competencies = () => {
  return (
    <>
        <Title>COMPETENCIES</Title>
        <Info>Prime Tech enterprises offers key competencies by leveraging expertise, technical competence, design standards and project
        execution methodologies to achieve the highest level of client success.</Info>
        <Slider />
    </>
  )
}

export default Competencies
