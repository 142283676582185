import styled from 'styled-components';
import { mobile } from '../../responsive'

const Container = styled.div`
    background: linear-gradient(
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.5)
    ), 
    url("https://t4.ftcdn.net/jpg/03/39/38/35/240_F_339383545_j1l1XcL6f3WV5kDqM4nxxKIA0t4YjUEI.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Text = styled.div`
    font-size: 3.5rem;
    color: white;
    margin-top: 2.5rem;

    ${mobile({
        paddingLeft: "2rem",
        fontSize: "2.5rem"
    })}
`;

const InnovationHeader = () => {
  return (
    <Container>
        <Text>
            INNOVATION
        </Text>
    </Container>
  )
}

export default InnovationHeader
