import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { mobile } from '../responsive';
const Container = styled.div`
    padding: 4rem 0;
    margin: auto;
    width: 90vw;
    overflow-x: hidden;

    ${mobile({
        marginTop: "1rem",
        padding: "0",
    })}
`;

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: .5rem;
    ${mobile({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    })}
`;

const Box = styled.div`
    background-color: #fff;
    color: #222;
    width: 400px;
    height: 580px;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

    ${mobile({
        width: "320px",
        heigth: "500px"
    })}
`;

const Info = styled.div`
    padding: 2rem 0rem;

    ${mobile({
        padding: "1rem 0"
    })}
`;

const Image = styled.img`
    height: 300px;
    background-size: cover;
    border-radius: 10px;

    ${mobile({
        height: "300px"
    })}
`;

const Type = styled.h4`
    font-size: 1.7rem;
    color: darkblue;
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    ${mobile({
        padding: "0rem 1rem"
    })}
`;

const About = styled.p`
    color: #222;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 1.5rem;
    font-size: 1rem;
`;

const Button = styled(Link)`
    text-decoration: none;
    display: flex;
    justify-content: center;
    color: #153391;
    border: 2px solid #153391;
    width: 150px;
    margin: auto;
    font-size: 1.2rem;
    padding: .6rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    border-radius: 50px;

    &:hover{
        background-color: #153391;
        color: white;
    }
`;

const Work = () => {
  return (
    <Container>
        <Wrapper>
            <Box>
                <Image src="https://d2t1xqejof9utc.cloudfront.net/screenshots/pics/cf2bc66da83f4d9ae1d87f0c36df13a6/large.png" />
                <Info>
                    <Type>SPOT WELDING FIXTURES</Type>
                    <About>A spot welding fixture is a tool used to hold subcomponent parts together ahead of being spot welded.</About>
                    <Button to="/spot" >Read more</Button>
                </Info>
            </Box>
            <Box>
                <Image src="https://t3.ftcdn.net/jpg/02/22/25/06/240_F_222250658_Rc0bgEGtLgDrewaSDxujRtQkOMmB1SQI.jpg" />
                <Info>
                    <Type>MIG WELDING FIXTURES</Type>
                    <About>A MIG welding fixture is designed in a way to support and locate the components to be welded to avoid distortions and weld stresses.</About>
                    <Button to="/mig" >Read more</Button>
                </Info>
            </Box>
            <Box>
                <Image src="https://www.technokraten.in/images/cylinder-cylinder-assembly-fixture.jpg" />
                <Info>
                    <Type>ASSEMBLE FIXTURES</Type>
                    <About>Assemble Fixture is a production tool that locates, holds and supports the work securely so that the required machining operations can be performed.</About>
                    <Button to="/assemble" >Read more</Button>
                </Info>
            </Box>
            <Box>
                <Image src="https://www.researchgate.net/profile/Filippo-Ciarapica/publication/264436886/figure/fig3/AS:563879799656458@1511450828593/Trolley-project-see-online-version-for-colours_Q320.jpg" />
                <Info>
                    <Type>TROLLEY AND PALLETS</Type>
                    <About>A pallet lifting trolley is simply a wheeled trolley constructed to lift and move pallets within a facility’s premises.</About>
                    <Button to="/trolley-pallets" >Read more</Button>
                </Info>
            </Box>
            <Box>
                <Image src="https://stamod.com/wp-content/uploads/2020/01/biw-fixture2.jpg" />
                <Info>
                    <Type>CHECKING FIXTURES</Type>
                    <About>A checking fixture is a quality assurance tool used in industries to check for the quality of components with complex shapes.</About>
                    <Button to="/mig-checking" >Read more</Button>
                </Info>
            </Box>
            <Box>
                <Image src="https://t3.ftcdn.net/jpg/01/08/71/84/240_F_108718414_TX1LE8LhzgnhXV6Ex3hsbaG5zymvWLhp.jpg" />
                <Info>
                    <Type>CAR SEAT FRAMES</Type>
                    <About>Prime Tech automotive seat frames are lightweight, durable and functional, offering best-in-class reclining range and noiseless operation.</About>
                    <Button to="/car-seat-frame" >Read more</Button>
                </Info>
            </Box>
            <Box>
                <Image src="https://4.imimg.com/data4/UH/KC/MY-9102151/mechanical-fixtur-250x250.jpg" />
                <Info>
                    <Type>RIVETING FIXTURES</Type>
                    <About>Riveting Fixtures allows them to join metal pieces together without having to resort to soldering or using heat.</About>
                    <Button to="/riveting" >Read more</Button>
                </Info>
            </Box>
        </Wrapper>
    </Container>
  )
}

export default Work
