import Img1 from './Assets/Picture2.jpg';
import Img2 from './Assets/Picture3.jpg';
import Img3 from './Assets/Picture4.jpg';
import Img4 from './Assets/Picture5.jpg';
import Img5 from './Assets/Picture6.jpg';
import Img6 from './Assets/Picture7.jpg';
import Img7 from './Assets/Picture8.jpg';
import Img8 from './Assets/Picture9.jpg';
import Img10 from './Assets/Project2.jpg';
import Img12 from './Assets/Project4.jpg';
import Img14 from './Assets/Project6.jpg';
import T1 from './Assets/Trolley1.jpg';
import T2 from './Assets/Trolley2.jpg';
import T3 from './Assets/Trolley3.jpg';
import T4 from './Assets/Trolley4.jpg';
import T5 from './Assets/Trolley5.jpg';
import T6 from './Assets/Trolley6.jpg';
import T7 from './Assets/Trolley7.png';
import T8 from './Assets/Trolley8.jpg';
import T9 from './Assets/Trolley9.jpg';
import T10 from './Assets/Trolley10.jpg';
import T11 from './Assets/Trolley11.jpg';
import T13 from './Assets/Trolley13.jpg';
import T14 from './Assets/Trolley14.jpg';
import T15 from './Assets/Trolley15.jpg';
import T16 from './Assets/Trolley16.jpg';
import T17 from './Assets/Trolley17.jpg';
import T18 from './Assets/Trolley18.jpg';
import T19 from './Assets/Trolley19.jpg';
import T20 from './Assets/Trolley20.jpg';
import T21 from './Assets/Trolley21.jpg';
import T22 from './Assets/Trolley22.jpg';
import M1 from './Assets/Mig1.jpg';
import M2 from './Assets/Mig2.jpg';
import M4 from './Assets/Mig4.jpg';
import M3 from './Assets/Mig3.jpg';
import M5 from './Assets/Mig5.jpg';
import M6 from './Assets/Mig6.jpg';
import M7 from './Assets/Mig7.jpg';
import M8 from './Assets/Mig8.jpg';
import M9 from './Assets/Mig9.jpg';
import M10 from './Assets/Mig10.jpg';
import M11 from './Assets/Mig11.jpg';
import M12 from './Assets/Mig12.jpg';
import M13 from './Assets/Mig13.jpg';
import M14 from './Assets/Mig14.png';
import M15 from './Assets/Mig15.jpg';
import M16 from './Assets/Mig16.png';
import M17 from './Assets/Mig17.png';
import M18 from './Assets/Mig18.png';
import M19 from './Assets/Mig19.jpg';
import R1 from './Assets/Riveting1.png';
import R2 from './Assets/Riveting2.jpg';
import R3 from './Assets/Riveting3.jpg';
import A1 from './Assets/Assemble1.jpg';
import A2 from './Assets/Assemble2.jpg';
import A3 from './Assets/Assemble3.jpg';
import S1 from './Assets/Spot1.png';
import S2 from './Assets/Spot2.jpg';
import S3 from './Assets/Spot3.jpg';
import S4 from './Assets/Spot4.jpg';
import MC1 from './Assets/MigChecking1.jpg';
import MC2 from './Assets/MigChecking2.jpg';
import MC3 from './Assets/MigChecking3.jpg';
import MC4 from './Assets/MigChecking4.jpg';
import MC5 from './Assets/MigChecking5.jpg';
import MC6 from './Assets/MigChecking6.jpg';
import MC7 from './Assets/MigChecking7.jpg';
import MC8 from './Assets/MigChecking8.jpg';

export const productItem = [
    {
        id: 1,
        name: "MILLING – 3NO’S",
        img: Img1
    },
    {
        id: 2,
        name: "SURFACE GRAINDING – 1NO",
        img: Img2
    },
    {
        id: 3,
        name: "LATHE – 2 NO’S",
        img: Img3
    },
    {
        id: 4,
        name: "BENCH GRAINDING – 1NO",
        img: Img4
    },
    {
        id: 5,
        name: "PANASONIC CO2 WELDING MACHINE – 2NO’S",
        img: Img5
    },
    {
        id: 6,
        name: "DRILLING MACHINE-2NO’S",
        img: Img6
    },
    {
        id: 7,
        name: "HAND CUTTING MACHINE – 1NO",
        img: Img7
    },
    {
        id: 8,
        name: "SQ TUBE CUTTING MACHINE – 1NO",
        img: Img8
    },
];

export const projectItem = [
    {
        id: 1,
        img: Img10,
        heading: "NISSAN MAGNITE PY1B CAR SEAT FRAME",
        text: "The first impression is good. You’ll find keyless entry access buttons on both front doors, unlike some rivals which have skipped the passenger side to save costs. Swing the door open after the sun’s gone down and you’ll be ushered in by a ‘welcome’ logo projected on the ground, and you’ll step inside a neat and modern-looking interior. The 7.0-inch full-digital coloured instrument panel is likely to get your attention first, with its funky, cartoon-like animations and different screen options that throw up ample information legibly.",
        direction: "none"
    },  
    {
        id: 2,
        img: Img12,
        heading: "RENAULT KIGER HBC CAR SEAT FRAME",
        text: "The first impression is good. You’ll find keyless entry access buttons on both front doors, unlike some rivals which have skipped the passenger side to save costs. Swing the door open after the sun’s gone down and you’ll be ushered in by a ‘welcome’ logo projected on the ground, and you’ll step inside a neat and modern-looking interior. The 7.0-inch full-digital coloured instrument panel is likely to get your attention first, with its funky, cartoon-like animations and different screen options that throw up ample information legibly.",
        direction: "reverse"
    },
    {
        id: 3,
        img: Img14,
        heading: "RENAULT TRIBER RBC & MY2020 CAR SEAT FRAME",
        text: "The first impression is good. You’ll find keyless entry access buttons on both front doors, unlike some rivals which have skipped the passenger side to save costs. Swing the door open after the sun’s gone down and you’ll be ushered in by a ‘welcome’ logo projected on the ground, and you’ll step inside a neat and modern-looking interior. The 7.0-inch full-digital coloured instrument panel is likely to get your attention first, with its funky, cartoon-like animations and different screen options that throw up ample information legibly.",
        direction: "none"
    },
]

export const palletsItem = [
    {
        id: 1,
        img: T1,
        text: "SMT BOARD STORAGE"
    },
    {
        id: 2,
        img: T2,
        text: "MULTI STORAGE TROLLEY"
    },
    {
        id: 3,
        img: T3,
        text: "HEATSINK TROLLEY"
    },
    {
        id: 4,
        img: T4,
        text: "ADJUSTABLE TRX TROLLEY"
    },
    {
        id: 5,
        img: T5,
        text: "LID STORAGE TROLLEY"
    },
    {
        id: 6,
        img: T6,
        text: "BATERY CHARGER UNIT"
    },
    {
        id: 7,
        img: T7,
        text: "FWLT BOARD STORAGE"
    },
    {
        id: 8,
        img: T8,
        text: "CHILLIE FILTER TROLLEY"
    },
    {
        id: 9,
        img: T9,
        text:"MASTER BOARD STORAGE"
    },
    {
        id: 10,
        img: T10,
        text: "TRX MULTI TROLLEY"
    },
    {
        id: 11,
        img: T11,
        text: "LID STORAGE MAGAZINES"
    },
    {
        id: 13,
        img: T13,
        text: "ANTENNA TROLLEY"
    },
    {
        id: 14,
        img: T14,
        text: "ASSEMBLE TROLLEY"
    },
    {
        id: 15,
        img: T15,
        text: "CHILD PARTS STORAGE"
    },
    {
        id: 16,
        img: T16,
        text: "FRONT WHEEL RACK"
    },
    {
        id: 17,
        img: T17,
        text: "PETROL TANK RACK"
    },
    {
        id: 18,
        img: T18,
        text: "PLASTIC PAD STORAGE"
    },
    {
        id: 19,
        img: T19,
        text: "BACK WHEEL RACK"
    },
    {
        id: 20,
        img: T20,
        text: "BODY FRAME RACK"
    },
    {
        id: 21,
        img: T21,
        text: "BLANK STEEL PALLET"
    },
    {
        id: 22,
        img: T22,
        text: "PAINTING METAL RACK"
    }
]

export const migItem = [
    {
        id: 1,
        img: M1,
        text: "RBC FRONT TUBE WF"
    },
    {
        id: 2,
        img: M2,
        text: "YAMAHA RR BRKT WF"
    },
    {
        id: 3,
        img: M3,
        text: "YAMAHA RR CHILD BRKT"
    },
    {
        id: 4,
        img: M4,
        text: "MAHINDRA M-REVA WF"
    },
    {
        id: 5,
        img: M5,
        text: "RBC SIDE BRKT WF"
    },
    {
        id: 6,
        img: M6,
        text: "PY1B CTR BRKT WF"
    },
    {
        id: 7,
        img: M7,
        text: "PY1B FRONT LINK WF"
    },
    {
        id: 8,
        img: M8,
        text: "PY1B FRT BRKT WF"
    },
    {
        id: 9,
        img: M9,
        text: "PY1B INBOARD WF"
    },
    {
        id: 10,
        img: M10,
        text: "PY1B BACK&CUSH WF"
    },
    {
        id: 11,
        img: M11,
        text: "PY1B FRONT TUBERH-LH WF"
    },
    {
        id: 12,
        img: M12,
        text: "PY1B CUSHION WF"
    },
    {
        id: 13,
        img: M13,
        text: "PY1B CUSHION WF"
    },
    {
        id: 14,
        img: M14,
        text: "M-REVA TUBE WF"
    },
    {
        id: 15,
        img: M15,
        text: "PY1B FRONT BACK WF"
    },
    {
        id: 16,
        img: M16,
        text: "M-REVA 2ND ROW WF"
    },
    {
        id: 17,
        img: M17,
        text: "HHA WIRE BRKT WF"
    },
    {
        id: 18,
        img: M18,
        text: "MCA PART MODELLING WF"
    },
    {
        id: 19,
        img: M19,
        text: "PEDAL BRAKE WF"
    }
]

export const rivetingItem = [
    {
        id: 1,
        img: R1,
        text: "MCA FRONT REVIT JIG"
    },
    {
        id: 2,
        img: R2,
        text: "HHA BRKT REVIT JIG"
    },
    {
        id: 3,
        img: R3,
        text: "PY1B HINJE BRKT RIVET JIG"
    }
]

export const assembleItem = [
    {
        id: 1,
        img: A1,
        text: "DEPANEL FIXTURE"
    },
    {
        id: 2,
        img: A2,
        text: "SCREW TIGHTING FIXTURE"
    },
    {
        id: 3,
        img: A3,
        text: "PRESS FIT TOOL FIXTURE"
    }
]

export const spotItem = [
    {
        id: 1,
        img: S1,
        text: "H79 DUSTER STUD WF"
    },
    {
        id: 2,
        img: S2,
        text: "PKB PANEL BRKT WF"
    },
    {
        id: 3,
        img: S3,
        text: "COMBI LAMP SUNNY CF"
    },
    {
        id: 4,
        img: S4,
        text: "HOOD LEDGE BRKT CF"
    }
]

export const migcheckingItem = [
    {
        id: 1,
        img: MC1,
        text: "PY1B ARMREST CF"
    },
    {
        id: 2,
        img: MC2,
        text: "PY1B 2W BACK&CUSH CF"
    },
    {
        id: 3,
        img: MC3,
        text: "PY1B 2ND 100P BACK CF"
    },
    {
        id: 4,
        img: MC4,
        text: "H79 DUSTER A80/81 CF"
    },
    {
        id: 5,
        img: MC5,
        text: "PY1B 1ST ROW BACK&CUSH RH/LH CF"
    },
    {
        id: 6,
        img: MC6,
        text: "PY1B 1ST ROW CUSH CF"
    },
    {
        id: 7,
        img: MC7,
        text: "PY1B 1ST ROW BACK RH/LH CF"
    },
    {
        id: 8,
        img: MC8,
        text: "PY1B 2ND ROW CTR HINGE CF"
    },
]

export const path = [
    {
        link: "/body-welding",
        title: "BODY WELDING SOLUTION"
    },
    {
        link: "/engineering-services",
        title: "ENGINEERING SERVICES"
    },
    {
        link: "/prototype-support",
        title: "PROTOTYPE SUPPORT"
    },
    {
        link: "/material-handling",
        title: "MATERIAL HANDLING"
    },
    {
        link: "/innovation",
        title: "INNOVATION"
    },
    {
        link: "/car-seat-frame",
        title: "CAR SEAT FRAME"
    },
    {
        link: "trolley-pallets",
        title: "TROLLEY & PALLETS"
    },
    {
        link: "/mig",
        title: "MIG FIXTURE"
    },
    {
        link: "/riveting",
        title: "RIVETING FIXTURE"
    },
    {
        link: "/assemble",
        title: "ASSEMBLE FIXTURE"
    },
    {
        link: "/spot",
        title: "SPOT FIXTURE"
    },
    {
        link: "/mig-checking",
        title: "MIG CHECKING FIXTURE"
    }
    
]