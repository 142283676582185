import styled from 'styled-components';
import { useState } from 'react';
import { mobile } from '../../responsive';

const Container = styled.div`
    color: white;
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    margin: 3rem 0rem;
    background: rgba(255, 255, 255, 0.2);

    ${mobile({
        padding: "1rem .5rem"
    })}
`;
const Title = styled.h2`
    font-size: 2.4rem;
    font-size: ${(props) => (props.type) == "title" && "3rem"};
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    color: ${(props) => (props.type) === "about" && "white"};
    color: ${(props) => (props.type) === "goals" && "red"};
    align-items: ${(props) => (props.type) === "goals" && "start"};
    justify-content: ${(props) => (props.type) === "goals" && "left"};
    margin-bottom: ${(props) => (props.type) === "goals" && "0rem"};
    margin-bottom: ${(props) => (props.type) === "about" && "1.5rem"};
    text-shadow: 2px 5px black;

    ${mobile({
        fontSize: "36px"
    })}
`;
const Arrow = styled.div`
    width: 50px;
    height: 50px;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${(props) =>
        props.direction === "left" && "200px"};
    right: ${(props) =>
        props.direction === "right" && "200px"};
    margin: auto;
    cursor: pointer;
    opacity: 1;
    z-index: 2;
    font-size: 2.8rem;

    ${mobile({
        display: "none"
    })}
`;
const Wrapper = styled.div`
    height: 350px;
    display: flex;
    transform: translateX(${(props) => 
        (props.slideIndex * -100) }vw);
    transition: all 1.5s ease;
`;
const InfoContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100vw;
    margin-top: 5rem;
    color: white;
    margin: auto;
`;
const Icon = styled.div`
    padding: 0;
    margin-right: 2rem;
    img{
        width: 70px;
        height: 70px;
        border-radius: 50%;    
    }

    ${mobile({
        display: "none"
    })}
`;
const About = styled.div`
    width: 50%;
    line-height: 1.6;
    font-size: 1.2rem; 
    display: ${(props) => (props.type) === "about" && "flex"};
    align-items: ${(props) => (props.type) === "about" && "center"};
    justify-content: ${(props) => (props.type) === "about" && "center"};
    margin-bottom: ${(props) => (props.type) === "about" && "1rem"};

    ${mobile({
        width: "80%",
        fontSize: "1.1rem"
    })}
`;
const List = styled.ul`
    padding-left: .9rem;
    
`;
const Item = styled.li`
    line-height: 1.6;
`;
const SlideContent = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 16px;
    align-items: center;
    width: 100%;
`;

const AboutUs = () => {
    const [slideIndex, setSlideIndex] = useState(-1);
    const handleClick = (direction) => {
        clearInterval(myInterval);
        if(direction === "left" ){
            setSlideIndex(slideIndex > -1 ? slideIndex - 1: 1);
        }
        else{
            setSlideIndex(slideIndex < 1? slideIndex + 1: -1);
        }
    }
    const myInterval = setInterval( () => handleClick("right"), 5000);
    
  return (
    <>
        <Title type="title">WHO WE ARE ?</Title>
        <Container>
            <Arrow direction="left" onClick={() => handleClick("left") }>
                 <i className="fa-solid fa-angle-left" />
            </Arrow>
            <Wrapper slideIndex={slideIndex}>
                <InfoContainer>
                    <Icon>
                        <img src="https://images.unsplash.com/photo-1521791136064-7986c2920216?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fGNvbXBhbnl8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60" />
                    </Icon>
                    <About>
                        We are an India based company founded with an objective of
                        "Contemporary world class standards in Welding and Line Automation."
                        Such as:
                        <List>
                            <Item>Line Automation</Item>
                            <Item>Robotic Welding Lines and Welding Cell</Item>
                            <Item>Welding SPM's</Item>
                            <Item>Welding Fixture and Gauges</Item>
                            <Item>Special Purpose Machines</Item>
                        </List>
                    </About>
                </InfoContainer>
                <InfoContainer>
                    <SlideContent>
                        <Title type="about">Vision</Title>
                        <About type="about">
                            Work together as team to "respect opinion and views of others".
                        </About>
                        <Title type="about">Mission</Title>
                        <About type="about">
                            Customer satisfaction is what we aspire for.
                        </About>
                    </SlideContent>
                </InfoContainer>
                <InfoContainer type="goals">
                    <Icon>
                        <img src="https://images.unsplash.com/photo-1596008194705-2091cd6764d4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8dGFyZ2V0fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60" />
                    </Icon>
                    <About>
                        <Title type="goals">Our Goals</Title>
                        <List>
                            <Item>We provide customers a defect & hassle-free experience through our design, software program and CMM, in-house.</Item>
                            <Item>Customer Focus</Item>
                            <Item>Quick response to Customer needs</Item>
                            <Item>Enhance customer satisfaction</Item>
                            <Item>Proven success</Item>
                        </List>
                    </About>
                </InfoContainer>  
            </Wrapper>
            <Arrow direction="right" onClick={() => handleClick("right")}>
                <i className="fa-solid fa-angle-right" />
            </Arrow>
        </Container>
    </>
  )
}

export default AboutUs
